import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TfiShoppingCart } from "react-icons/tfi";
import { GiRoundStar } from "react-icons/gi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CategoryProduct } from "../../DAL/Categories/Category";
import { picBaseUrl } from "../../config/config";
import { useScroll } from "../../hooks/AppContext";
import { AllTrendingProducts } from "../../DAL/TrendingProducts/Trending";

export default function TrendingProducts() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const { NoToken } = useScroll();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  //   const [totalPages, setTotalPages] = useState(1);

  const GetTrendingProduct = async () => {
    setLoading(true);
    const resp = await AllTrendingProducts();

    if (resp?.status == true) {
      setData(resp?.data);
      //   setTotalPages(Math.ceil(resp?.data?.total / resp?.data?.per_page));
      setLoading(false);
    }
  };

  //   const handlePageChange = (event, value) => {
  //     setPage(value);
  //   };

  useEffect(() => {
    NoToken();
    GetTrendingProduct();
  }, []);
  return (
    <>
      <div className="mainDiv">
        <div style={{ width: "96%", margin: "auto" }}>
          <Typography variant="h5" className="text-white mt-4 mx-3">
            Trending Products
          </Typography>
          {/* </div> */}
          {loading == false && data?.length == 0 && (
            <h3 className="text-center text-white mt-5 mb-5">Data not found</h3>
          )}
          <Grid container spacing={2}>
            {/* add skeleton loader */}
            {loading == true &&
              Array.from({ length: 10 }).map((val) => (
                <>
                  <div className="col-lg-3 mt-4">
                    <Skeleton
                      sx={{ height: 340, width: "100%", borderRadius: "20px" }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </div>
                </>
              ))}
            {/* show api data */}

            {loading == false &&
              data?.map((val) => (
                <>
                  <Grid item lg={3} xs={6}>
                    <Card
                      className="card favouriteCards h-100"
                      sx={{
                        padding: {
                          lg: "10px 10px 0px 10px",
                          md: "10px 10px 0px 10px",
                          sm: "5px 5px 0px 5px",
                          xs: "6px 6px 0px 6px",
                        },
                        width: "100%",
                        cursor: "pointer",
                      }}
                      onClick={() => navigate(`/product-detail/${val?.id}`)}
                    >
                      {val?.discount == 0 ? (
                        ""
                      ) : (
                        <button className="discountBtn">
                          {val?.discount + "%"}
                        </button>
                      )}

                      <CardMedia
                        sx={{
                          height: { lg: 170, md: 170, sm: 130, xs: 120 },
                          borderRadius: "10px",
                        }}
                        image={picBaseUrl + val?.thumbnail}
                        alt=""
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1, // This will make the content fill the vertical space
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="text-white"
                          sx={{
                            fontSize: {
                              lg: "1.3rem",
                              md: "1rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          {val?.name.length > 20
                            ? val?.name.substring(0, 20) + "..."
                            : val?.name}
                        </Typography>

                        <Typography
                          gutterBottom
                          variant="p"
                          component="div"
                          className="text-white"
                          sx={{
                            fontSize: {
                              lg: "1.3rem",
                              md: "1rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          Rs. {val?.price}
                        </Typography>
                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex ">
                            <GiRoundStar
                              sx={{
                                fontSize: {
                                  lg: "1.3rem",
                                  md: "1rem",
                                  sm: "0.76rem",
                                  xs: "0.76rem",
                                },
                              }}
                              color="gold"
                            />
                            <span
                              className="mx-2  text-secondary"
                              sx={{
                                fontSize: {
                                  lg: "1.3rem",
                                  md: "1rem",
                                  sm: "0.76rem",
                                  xs: "0.76rem",
                                },
                              }}
                            >
                              {val?.rating} <span>({val?.reviews_count})</span>
                            </span>
                          </div>
                          <TfiShoppingCart
                            style={{ fontSize: "31px", marginTop: "10px" }}
                            color="gold"
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              ))}
          </Grid>
        </div>
        {/* {data?.length > 0 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            sx={{
              mt: 4,
              display: "flex",
              justifyContent: "center",
              "& .MuiPaginationItem-root": {
                color: "white", // Set color of pagination items to gold
                borderColor: "gold", // Set outline color to gold
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "gold", // Set selected item background color to gold
                color: "black", // Set selected item text color to white
              },
              "& .MuiPaginationItem-root:hover": {
                backgroundColor: "gold", // Hover background color
                color: "black",
              },
            }}
            shape="rounded"
          />
        )} */}
      </div>
    </>
  );
}
