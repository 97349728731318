import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { GiRoundStar } from "react-icons/gi";
import { TfiShoppingCart } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { TopSellerProducts } from "../../DAL/Products/Products";
import { Box, Chip, Skeleton } from "@mui/material";
import { picBaseUrl } from "../../config/config";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";

export default function SecondStore() {
  const navigate = useNavigate();
  const [data, setData] = React.useState(
    JSON.parse(localStorage.getItem("second_store_data")) || []
  );

  const GetTopSellerProducts = async () => {
    const resp = await TopSellerProducts();
    if (resp?.status == true) {
      localStorage.setItem("second_store_data", JSON.stringify(resp?.data[1]));
      return resp?.data[1];
    }
  };

  const { isLoading, isError, error } = useQuery(
    "secondtopSellerProductsList",
    GetTopSellerProducts,
    {
      initialData: data,
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (newData) => {
        setData(newData);
      },
    }
  );

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  if (isError) {
    return enqueueSnackbar(error?.message, { variant: "error" });
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <Typography variant="h6" className="text-white">
          {data?.shop_name}
        </Typography>
        <Chip
          label="See All"
          sx={{
            backgroundColor: "black",
            color: "gold",
            cursor: "pointer",
            "&:hover": { backgroundColor: "black" },
          }}
          onClick={() => {
            handleScrollToTop();
            navigate("/all-products", { state: data });
          }}
        />
      </div>
      <div className="cardMain">
        {isLoading &&
          Array.from({ length: 5 }).map(() => (
            <>
              <Skeleton
                sx={{ height: 440, width: 325, borderRadius: "20px" }}
                animation="wave"
                variant="rectangular"
              />
            </>
          ))}
        {!isLoading &&
          data?.products?.map((val) => (
            <>
              <Card
                className="card cardTrending "
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  handleScrollToTop();
                  navigate(`/product-detail/${val?.id}`);
                }}
              >
                {val?.discount !== 0 && (
                  <button className="discountBtn">{val?.discount + "%"}</button>
                )}
                <img
                  style={{
                    width: "100%",
                    height: "200px",
                    borderRadius: "10px",
                    objectFit: "fill",
                  }}
                  src={picBaseUrl + val?.thumbnail}
                  alt=""
                />
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    gutterBottom
                    variant="h6"
                    component="div"
                    className="text-white"
                    sx={{
                      whiteSpace: "normal",
                      wordWrap: "breakWord",
                      fontSize: { sm: "17px", lg: "20px", xs: "17px" },
                    }}
                  >
                    {val?.name.length > 20
                      ? val?.name.substring(0, 20) + "..."
                      : val?.name}
                  </Typography>

                  <Typography
                    gutterBottom
                    variant="p"
                    component="div"
                    className="text-white"
                  >
                    Rs. {val?.price}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <div className="d-flex ">
                      <GiRoundStar style={{ fontSize: "27px" }} color="gold" />
                      <span
                        className="mx-2  text-secondary"
                        style={{ fontSize: "19px" }}
                      >
                        {val?.rating} <span>({val?.reviews_count})</span>
                      </span>
                    </div>
                    <TfiShoppingCart
                      style={{ fontSize: "31px", marginTop: "10px" }}
                      color="gold"
                    />
                  </div>
                </CardContent>
              </Card>
            </>
          ))}
      </div>
    </>
  );
}
