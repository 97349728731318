import { invokeApi } from "../../utils";

export const FavouriteProducts = async () => {
  const requestObj = {
    path: `/api/user/product/favourite`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const ProductDetail = async (id) => {
  const requestObj = {
    path: `/api/user/product/detail/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const TopSellerProducts = async () => {
  const requestObj = {
    path: `/api/web/user/top/seller/product`,
    method: "GET",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const FavoriteUnFavorite = async (data) => {
  const requestObj = {
    path: `/api/user/product/togglefavourite`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const ProductReviews = async (id) => {
  const requestObj = {
    path: `/api/user/product/review/list/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const ProductReviewsAdd = async (data) => {
  const requestObj = {
    path: `/api/user/product/review/save`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const ProductReviewsDelete = async (id) => {
  const requestObj = {
    path: `/api/user/product/review/delete/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const ProductReviewsUpdate = async (id, data) => {
  const requestObj = {
    path: `/api/user/product/review/update/${id}`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const AddToCart = async (data) => {
  const requestObj = {
    path: `/api/user/product/cart`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};