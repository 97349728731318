import { invokeApi } from "../../utils";

export const BannerList = async () => {
  const requestObj = {
    path: `/api/web/user/banner/list`,
    method: "GET",
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  return invokeApi(requestObj);
};
