import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tabs,
  Card,
  Tab,
  Box,
  Skeleton,
  Avatar,
  Button,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  CompleteListing,
  DeliveredListing,
  OrderDeliveredImages,
  PendingListing,
  ProgressListing,
} from "../../DAL/Order/Order";
import { picBaseUrl } from "../../config/config";
import UserListHead from "../../components/UserListHead";
import Scrollbar from "../../components/Scrollbar";
import noOrder from "../../assets/images/no order.png";
import ImagesModel from "./ImagesModel";
import moment from "moment";

const TABLE_HEAD = [
  {
    id: "thumbnail",
    label: "Image",
    alignCenter: false,
  },
  { id: "Title", label: "Product Name", alignCenter: true },
  { id: "Order no", label: "Order no", alignCenter: true },
  { id: "quantity", label: "Quantity", alignCenter: true },
  { id: "Size", label: "Size", alignCenter: true },
  { id: "Total Amount", label: "Total Amount", alignCenter: true },
  { id: "Advance", label: "Advance", alignCenter: true },
  { id: "date", label: "Order Placement", alignCenter: true },
  { id: "Remaining", label: "Remaining", alignCenter: true },
  { id: "returned", labael: "Return", alignCenter: true },
];
const MyOrder = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState(0);
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("placed");
  const [open, setOpen] = useState(false);
  const [imagesData, setImagesData] = useState([]);
  const handleTabChange = (event, newValue) => {
    setStatus(newValue);
  };

  const GetPendingList = async () => {
    setLoading(true);
    setTitle("pending");
    const resp = await PendingListing();

    if (resp?.status == true) {
      setOrder(resp?.data);
      setLoading(false);
    }
  };

  const handlePendingData = () => {
    GetPendingList();
    setTitle("placed");
  };

  const handleProgressData = async () => {
    setLoading(true);
    setTitle("in-progress");
    const resp = await ProgressListing();
    if (resp?.status == true) {
      setOrder(resp?.data);
      setLoading(false);
    }
  };

  const handleCompleteData = async () => {
    setLoading(true);
    setTitle("completed");
    const resp = await CompleteListing();
    if (resp?.status == true) {
      setOrder(resp?.data);
      setLoading(false);
    }
  };

  const handleDeliveredData = async () => {
    setLoading(true);
    setTitle("delivered");
    const resp = await DeliveredListing();
    if (resp?.status == true) {
      setOrder(resp?.data);
      setLoading(false);
    }
  };

  const handleShowImages = async (id) => {
    setIsLoading(true);
    const resp = await OrderDeliveredImages(id);
    if (resp?.status == true) {
      setImagesData(resp?.data);
      setIsLoading(false);
      setOpen(true);
    }
  };

  const handleReturnedData = () => {
    setTitle("returned");
  };

  useEffect(() => {
    GetPendingList();
  }, []);
  return (
    <>
      <ImagesModel open={open} setOpen={setOpen} data={imagesData} />
      <div className="mainDiv">
        <h3 className="text-white text-center">My Orders</h3>
        <Box
          sx={{
            width: "auto",
            typography: "body1",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Tabs
            className="my-4"
            value={status}
            onChange={handleTabChange}
            aria-label="order status tabs"
            variant="scrollable"
            centered
            sx={{
              borderBottom: "1px solid #CBCBCB",
              width: { xs: "100%", sm: "auto", md: "auto", lg: "auto" },
              margin: "auto",
              "& .MuiTab-root": { color: "white" },
              "& .Mui-selected": { color: `gold !important` },
              "& .MuiTabs-indicator": { backgroundColor: "gold" },
              overflowX: "scroll",
              scrollbarWidth: "none",
            }}
          >
            <Tab label="Pending" onClick={handlePendingData} />
            <Tab label="In-Process" onClick={handleProgressData} />
            <Tab label="Completed" onClick={handleCompleteData} />
            <Tab label="Delivered" onClick={handleDeliveredData} />
            <Tab label="Returned" onClick={handleReturnedData} />
          </Tabs>
          <div className="container">
            {loading === true &&
              Array.from({ length: 2 }).map(() => (
                <>
                  <Skeleton
                    sx={{
                      height: 80,
                      width: "100%",
                      margin: "auto",
                      borderRadius: "10px",
                      marginTop: "10px",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                </>
              ))}
          </div>
          {loading == false && order?.length == 0 && (
            <>
              <div className="text-center mt-3">
                <img src={noOrder} alt="" width={250} height={120} />
                <h5 className="text-white text-center mt-3">
                  There are no orders {title} yet
                </h5>
              </div>
            </>
          )}
          {loading == false && order?.length > 0 && (
            <div className="container">
              <Card
                sx={{
                  boxShadow: "none",
                  mb: 1,
                  mt: 3,
                  backgroundColor: "#191919",
                }}
              >
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead headLabel={TABLE_HEAD} />

                      <TableBody>
                        {order?.map((row, index) => {
                          const {
                            id,
                            product,
                            order_no,
                            advance,
                            remaining,
                            quantity,
                            size,
                            amount,
                            created_at,
                          } = row;

                          return (
                            <TableRow
                              hover
                              tabIndex={-1}
                              role="checkbox"
                              className="tableBodyOrders"
                            >
                              <TableCell sx={{ p: 0 }}>
                                <img
                                  style={{
                                    width: "100px",
                                    height: "90px",
                                  }}
                                  alt={product?.name}
                                  src={picBaseUrl + product?.thumbnail}
                                />
                              </TableCell>
                              <TableCell align="center" className="text-white">
                                {product == null ? "-" : product?.name}
                              </TableCell>
                              <TableCell align="center" className="text-white">
                                {order_no}
                              </TableCell>
                              <TableCell align="center" className="text-white">
                                {quantity}
                              </TableCell>
                              <TableCell align="center" className="text-white">
                                {size}
                              </TableCell>
                              <TableCell align="center" className="text-white">
                                {amount}
                              </TableCell>

                              <TableCell align="center" className="text-white">
                                {advance}
                              </TableCell>
                              <TableCell align="center" className="text-white">
                                {remaining}
                              </TableCell>
                              <TableCell align="center" className="text-white">
                                {moment(created_at).format("YY-MM-DD")}
                              </TableCell>
                              <TableCell>
                                {status === 3 && (
                                  <>
                                    <div className="d-flex flex-wrap gap-1">
                                      <Button
                                        size="small"
                                        variant="outlined"
                                        disabled={isLoading == true}
                                        sx={{
                                          textTransform: "none",
                                          borderColor: "gold",
                                          color: "gold",
                                        }}
                                        className="outline-btn fw-bold"
                                        onClick={() => handleShowImages(id)}
                                      >
                                        {isLoading == false ? (
                                          "View Images"
                                        ) : (
                                          <CircularProgress
                                            sx={{ color: "black" }}
                                            size={20}
                                          />
                                        )}
                                      </Button>
                                      <Button
                                        sx={{
                                          textTransform: "none",
                                          borderColor: "gold",
                                          color: "gold",
                                        }}
                                        variant="outlined"
                                        className="outline-btn fw-bold"
                                        size="small"
                                        onClick={() =>
                                          navigate("/return-product")
                                        }
                                      >
                                        Return
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Scrollbar>
              </Card>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};

export default MyOrder;
