import { FormHelperText } from "@mui/material";
import React, { useEffect } from "react";

const Refund = () => {
  useEffect(() => {
    document.title = "Return & Refund Policy || Pakistan&#039;s 1st Online Gold Jewellery Shopping Bazaar";
  }, []);
  return (
    <>
      <div className="container policy mainDiv  text-white">
        <div className="text-center">
          <p className="text-center">Last updated: August 26, 2024</p>
          <h1 className="text-warning">Return & Refund Policy</h1>
        </div>
        <div className="refund">
          <p>
            At Gold Bazaar, we are committed to providing you with high-quality
            gold products and ensuring your satisfaction. If you are not
            completely satisfied with your purchase, we are here to help. Please
            read our return & refund policy carefully to understand your rights
            and obligations.
          </p>
          <h2>How to Return a Product </h2>
          <ul>
            <li>
              <FormHelperText className="text-white">
                Log into the Gold Bazaar Mobile Application.
              </FormHelperText>
            </li>
            <li>
              <FormHelperText className="text-white">
                Select Orders from the bottom menu.
              </FormHelperText>
            </li>
            <li>
              {" "}
              <FormHelperText className="text-white">
                Go to the Completed tab.
              </FormHelperText>
            </li>
            <li>
              <FormHelperText className="text-white">
                Tab on Return button.
              </FormHelperText>
            </li>
            <li>
              <FormHelperText className="text-white">
                Your request will be sent to Gold bazaar then our customer
                support team will contact you. For this you have to provide your
                order number, details and photos of the product you wish to
                return, and the reason for the return. Our team will guide you
                through the process.
              </FormHelperText>
            </li>
          </ul>
          <h2>Conditions for Return</h2>
          <ul>
            <li>
              <FormHelperText className="text-white">
                You may request a return within 3 days from the date you
                received your item.
              </FormHelperText>
            </li>
            <li>
              <FormHelperText className="text-white">
                The item must be in its original condition, unused, unaltered.
                Items showing signs of wear or that have been resized or altered
                in any way are not eligible for return.
              </FormHelperText>
            </li>
            <li>
              <FormHelperText className="text-white">
                The item must be returned in its original packaging, including
                any boxes.
              </FormHelperText>
            </li>
            <li>
              {" "}
              <FormHelperText className="text-white">
                Delivered product is incomplete (i.e. has missing items).
              </FormHelperText>{" "}
            </li>
            <li>
              <FormHelperText className="text-white">
                Delivered product is incorrect (i.e. wrong product/size/colour).
              </FormHelperText>
            </li>
            <li>
              <FormHelperText className="text-white">
                Delivered product does not match product description or picture
                (i.e product not as advertised)
              </FormHelperText>
            </li>
            <li>
              {" "}
              <FormHelperText className="text-white">
                Customized or personalized products cannot be returned.
              </FormHelperText>
            </li>
            <li>
              {" "}
              <FormHelperText className="text-white">
                If you receive a damaged or defective item.
              </FormHelperText>{" "}
            </li>
          </ul>
          <h2>Inspection and Approval</h2>
          <p>
            Once we receive and inspect your returned item, we will notify you
            of the approval or rejection of your refund. If approved, your
            refund will be processed within 7 business days and credited to your
            original method of payment.
          </p>
          <h2>Further Assistance</h2>
          <p>
            If you haven’t received a refund yet, please first check your bank
            account again. Then, contact us at +92 300 4425678.
          </p>
        </div>
      </div>
    </>
  );
};

export default Refund;
