import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { picBaseUrl } from "../../config/config";
import { Box, Typography } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

export default function HeroSectionSlider({ dataArray }) {
  const centerActiveDot = () => {
    const dotsContainer = document.querySelector(".slick-dots");
    const activeDot = dotsContainer.querySelector("li.slick-active");

    if (dotsContainer && activeDot) {
      const containerWidth = dotsContainer.offsetWidth;
      const activeDotOffset = activeDot.offsetLeft + activeDot.offsetWidth / 2;
      const scrollPosition = activeDotOffset - containerWidth / 2;

      dotsContainer.scrollLeft = scrollPosition;
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    autoplaySpeed: 4000,
  };

  return (
    <>
      {dataArray?.length > 1 ? (
        <>
          <Slider {...settings}>
            {dataArray.map((val, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "auto",
                }}
              >
                <img
                  className="carousel-hero-image"
                  src={picBaseUrl + val?.banner}
                  style={{
                    maxWidth: "100%",
                    background:
                      "linear-gradient(to top, #0000004e, transparent)",
                  }}
                  alt={`Slide ${index + 1}`}
                />
                <Typography
                  variant="h6"
                  className="text-white"
                  sx={{
                    position: "absolute",
                    bottom: { lg: 20, sm: 10, xs: 10 },
                    right: 30,
                    cursor: "pointer",
                    fontSize: { sm: "18px", xs: "18px" },
                  }}
                >
                  <a
                    href={val?.url}
                    target="_blank"
                    style={{
                      cursor: "pointer",
                      textShadow: "2px 2px 6px #000",
                    }}
                    className="text-white text-decoration-none"
                  >
                    {"Explore >"}
                  </a>
                </Typography>
              </Box>
            ))}
          </Slider>
        </>
      ) : (
        <div className="carousel-hero">
          <Box
            sx={{
              position: "relative",
              width: "100%",
            }}
          >
            <img
              className="carousel-hero-image"
              src={picBaseUrl + dataArray[0]?.banner}
              style={{
                height: "auto",
                width: "100%",
                objectFit: "cover",
              }}
              alt="Single Slide"
            />
            <Typography
              variant="h6"
              className="text-white"
              style={{
                position: "absolute",
                bottom: 20,
                right: 30,
                cursor: "pointer",
              }}
            >
              <a
                href={dataArray[0]?.url}
                target="_blank"
                style={{ cursor: "pointer", textShadow: "2px 2px 6px #000" }}
                className="text-white text-decoration-none "
              >
                {"Explore >"}
              </a>
            </Typography>
          </Box>
        </div>
      )}
    </>
  );
}
