import { Button, Card, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";
import { OrderSave } from "../../DAL/Order/Order";
import { useSnackbar } from "notistack";
import { useScroll } from "../../hooks/AppContext";

export default function Invoice({ setActive }) {
  const { state } = useLocation();
  console.log(state, "djskfhksdjklfj");
  const [address, setAddress] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { GetCartQuantity } = useScroll();
  const [productsData, setProductsData] = useState([]);
  const [updatedProductsData, setUpdatedProductsData] = useState([]);
  console.log(updatedProductsData, "jkdhgkjdfkljglf");
  const [totalAmount, setTotalAmount] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sellerProductIds = updatedProductsData?.map((product) => ({
      seller_id: product?.products?.seller_id,
      product_id: product?.product_id,
      size: product?.size,
      quantity: product?.quantity,
      remaining: product?.products?.remainingAmount,
      advance: product?.products?.advancePayment,
      amount:
        product?.products?.discount > 0
          ? product?.products?.with_discount_price
          : product?.products?.price,
    }));

    const formData = new FormData();
    formData.append("email", address?.email);
    formData.append("phone", address?.phone);
    formData.append("address", address?.address);
    formData.append("advance_pay", state?.advancePayment);
    formData.append("remaining_amount", state?.remaining);
    formData.append("total_amount", totalAmount);
    formData.append("shipping_method", state?.remaining == 0 ? "100%" : "20%");
    formData.append("sub_order", JSON.stringify(sellerProductIds));
    const resp = await OrderSave(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      setActive(3);
      GetCartQuantity();
      localStorage.removeItem("billing_address");
      localStorage.removeItem("cartItems");
      localStorage.removeItem("totalAmount");
      localStorage.removeItem("updatedValue");
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem("billing_address"));
    const cartProducts = JSON.parse(localStorage.getItem("cartItems"));
    const totalCartAmount = localStorage.getItem("totalAmount");
    const updatedAmount = JSON.parse(localStorage.getItem("updatedValue"));
    setUpdatedProductsData(updatedAmount);
    setTotalAmount(totalCartAmount);
    setProductsData(cartProducts);
    setAddress(data);
  }, [state]);
  return (
    <>
      <div
        style={{
          backgroundColor: "#000000",
          padding: "30px",
          marginTop: "2rem",
          borderRadius: "10px",
        }}
      >
        <Typography variant="h5" className="fw-bold text-white">
          Order Detail
        </Typography>
        <div className="row">
          <div className="col-lg-6 ">
            <div className="row mt-4 ">
              <div className="col-lg-3">
                <Typography variant="subtitle2" className="text-secondary ">
                  Name
                </Typography>
              </div>
              <div className="col-lg-9">
                <Typography variant="subtitle2" className="text-white ">
                  {address?.name}
                </Typography>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-3">
                <Typography variant="subtitle2" className="text-secondary ">
                  Email
                </Typography>
              </div>
              <div className="col-lg-9">
                <Typography variant="subtitle2" className="text-white ">
                  {address?.email}
                </Typography>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-3">
                <Typography variant="subtitle2" className="text-secondary ">
                  Phone
                </Typography>
              </div>
              <div className="col-lg-9">
                <Typography variant="subtitle2" className="text-white ">
                  {address?.phone}
                </Typography>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-3">
                <Typography variant="subtitle2" className="text-secondary ">
                  Address
                </Typography>
              </div>
              <div className="col-lg-9">
                <Typography variant="subtitle2" className="text-white ">
                  {address?.address}
                </Typography>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-3">
                <Typography variant="subtitle2" className="text-secondary ">
                  Payment Method
                </Typography>
              </div>
              <div className="col-lg-9">
                <Typography variant="subtitle2" className="text-white ">
                  {state?.remaining == 0
                    ? "Full Payment Advance"
                    : "20% Advance"}
                </Typography>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-3">
                <Typography variant="subtitle2" className="text-secondary ">
                  Total Amount
                </Typography>
              </div>
              <div className="col-lg-9">
                <Typography variant="subtitle2" className="text-white ">
                  {totalAmount}
                </Typography>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-lg-3">
                <Typography variant="subtitle2" className="text-secondary ">
                  Advance Payment
                </Typography>
              </div>
              <div className="col-lg-9">
                <Typography variant="subtitle2" className="text-white ">
                  {state.advancePayment}
                </Typography>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-lg-3">
                <Typography variant="subtitle2" className="text-secondary ">
                  Remaining Payment
                </Typography>
              </div>
              <div className="col-lg-9">
                <Typography variant="subtitle2" className="text-white ">
                  {state?.remaining?.toFixed(2)}
                </Typography>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <Typography variant="h6" className="text-white fw-bold">
              Products
            </Typography>
            <div
              style={{
                height: "45vh",
                overflowY: "scroll",
              }}
            >
              {productsData?.map((val) => {
                return (
                  <>
                    <Card
                      className="card invoice-card mt-3"
                      sx={{ padding: ".5rem", borderRadius: "10px" }}
                    >
                      <div className="row">
                        <div className="col-lg-3">
                          {val?.products?.discount > 0 && (
                            <button className="discountBtn-Invoice">
                              {val?.products?.discount + "%"}
                            </button>
                          )}
                          <img
                            src={picBaseUrl + val?.products?.thumbnail}
                            style={{
                              width: "100%",
                              height: "100px",
                              borderRadius: "10px",
                            }}
                            alt=""
                          />
                        </div>
                        <div className="col-lg-9">
                          <Typography variant="h6" className="text-warning">
                            {val?.products?.name}
                          </Typography>

                          <Typography
                            variant="body1"
                            sx={{
                              textDecoration:
                                val?.products?.discount > 0
                                  ? "line-through"
                                  : "none",
                            }}
                            className="text-cart text-white"
                          >
                            Rs. {val?.products?.price.toFixed(2)}
                          </Typography>
                          {val?.products?.discount > 0 && (
                            <Typography
                              variant="body1"
                              color="grey"
                              className="text-cart"
                            >
                              Rs.
                              {val?.products?.discount > 0
                                ? val?.products?.with_discount_price.toFixed(2)
                                : val?.products?.discount.toFixed(2)}
                            </Typography>
                          )}
                          <Typography className="text-secondary">
                            Size: {val?.size} | Quantity: {val?.quantity}
                          </Typography>
                        </div>
                      </div>
                    </Card>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <Button
            variant="outlined"
            className="outline-btn mt-3 fw-bold"
            sx={{
              color: "#ffc107",
              borderColor: "#ffc107",
              textTransform: "none",
            }}
            onClick={handleSubmit}
          >
            Place Order
          </Button>
        </div>
      </div>
    </>
  );
}
