import React, { useEffect, useState } from "react";
import logo from "../../assets/images/Goldlogo.png";
import { FaPenSquare } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import SuccessModel from "./SuccessModel";
import { SignUp } from "../../DAL/Auth/auth";
import { useSnackbar } from "notistack";
import {
  Box,
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import Iconify from "../../components/Iconify";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { FaPen } from "react-icons/fa6";

const Signup = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(false);
  const [showPass, setShowPass] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [inputs, setInputs] = useState({
    username: "",
    email: "",
    password: "",
    confirm_password: "",
    image: "",
    phone: "",
    address: "",
  });

  const [error, setError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [phone, setPhone] = useState("");
  const [currentAddress, setCurrentAddress] = useState(null);

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setInputs((prev) => ({ ...prev, ["image"]: file }));
    setImagePreview(URL.createObjectURL(file));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if(!isChecked){
      enqueueSnackbar("Please select agree to the Terms of Use & Privacy Policy", {
        variant: "error",
      });
      return;
    }

    if (
      inputs?.password !== "" &&
      inputs?.password !== inputs?.confirm_password
    ) {
      enqueueSnackbar("Password and confirm password does not match", {
        variant: "error",
      });
      return;
    }
    if (inputs?.password.includes(" ")) {
      enqueueSnackbar("Password should not contain empty space", {
        variant: "error",
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("username", inputs?.username);
    formData.append("email", inputs?.email);
    formData.append("phone_no", phone);
    formData.append("address", inputs?.address);
    formData.append("password", inputs?.password);
    formData.append("password_confirmation", inputs?.confirm_password);
    formData.append("image", inputs?.image);

    const resp = await SignUp(formData);
    if (resp?.status == true) {
      setOpen(true);
    } else {
      setIsLoading(false);
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setError(resp?.message);
        enqueueSnackbar("Plesae fill the required fields", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setCurrentAddress(data.address));
    });
  }, []);

  return (
    <>
      <SuccessModel open={open} setOpen={setOpen} />
      <div>
        <div className="text-center text-white pb-5">
          <img src={logo} alt="" width={130} height={100} className="mt-4" />
          <div className="Sign-up mt-5 p-5">
            <h4>Create Account</h4>
            <p className="text-center">
              Your Location:{" "}
              <span className="text-warning">{currentAddress?.country || "Unknown" }</span>
            </p>
            <div
              style={{
                position: "relative",
                width: "100px",
                height: "100px",
                margin: "0 auto",
              }}
            >
              <img
                src={imagePreview || "dummy2.png"}
                alt=""
                style={{
                  height: "100px",
                  width: "100px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  border: "2px solid gold",
                  display: "block",
                }}
              />
              <input
                type="file"
                id="imageUpload"
                onChange={handleImageChange}
                style={{ display: "none" }}
              />
              <label
                htmlFor="imageUpload"
                style={{
                  position: "absolute",
                  bottom: "5px",
                  right: "5px",
                  cursor: "pointer",
                }}
              >
                <FaPen
                  className="text-black p-1 h5"
                  style={{
                    margin: 0,
                    backgroundColor:"gold",
                    borderRadius:"5px"
                  }}
                />
              </label>
            </div>
            <FormHelperText
              className="text-danger text-center"
              sx={{ fontSize: "10px" }}
            >
              {error?.image && error?.image[0]}
            </FormHelperText>
            <div className="text-start">
              <label>Full Name *</label>
              <input
                type="text"
                className="input p-2 "
                name="username"
                onChange={handleChange}
              />
              <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
                {error?.username && error?.username[0]}
              </FormHelperText>
              <br />
              <label>Email *</label>
              <input
                type="email"
                className="input p-2 "
                name="email"
                onChange={handleChange}
              />
              <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
                {error?.email && error?.email[0]}
              </FormHelperText>
              <br />
              <div>
                <label>Password *</label>
              </div>
              <TextField
                value={inputs?.password}
                onChange={handleChange}
                fullWidth
                size="small"
                name="password"
                autoComplete="current-password"
                type={showPass ? "text" : "password"}
                InputLabelProps={{
                  style: { color: "silver" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPass(!showPass)}
                        edge="end"
                      >
                        <Iconify
                          icon={showPass ? "eva:eye-fill" : "eva:eye-off-fill"}
                          sx={{ color: "white" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  input: { color: "white" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "silver",
                    },
                    "&:hover fieldset": {
                      borderColor: "silver",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "silver",
                    },
                  },
                }}
              />
              <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
                {error?.password && error?.password[0]}
              </FormHelperText>
              <br />
              <div>
                <label>Confirm Password *</label>
              </div>
              <TextField
                value={inputs?.confirm_password}
                onChange={handleChange}
                fullWidth
                size="small"
                name="confirm_password"
                autoComplete="current-password"
                type={showConfirmPass ? "text" : "password"}
                InputLabelProps={{
                  style: { color: "silver" },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowConfirmPass(!showConfirmPass)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showConfirmPass
                              ? "eva:eye-fill"
                              : "eva:eye-off-fill"
                          }
                          sx={{ color: "white" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  input: { color: "white" },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "silver",
                    },
                    "&:hover fieldset": {
                      borderColor: "silver",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "silver",
                    },
                  },
                }}
              />
              <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
                {error?.password_confirmation &&
                  error?.password_confirmation[0]}
              </FormHelperText>
              <br />
              <label htmlFor="phone">Phone no *</label>
              <PhoneInput
                country={"pk"}
                value={phone}
                onChange={(val) => setPhone(val)}
                inputProps={{
                  required: true,
                }}
                inputStyle={{
                  width: "100%",
                  backgroundColor: "black",
                  border: "1px solid white",
                  color: "white",
                }}
                buttonStyle={{
                  backgroundColor: "black",
                  border: "1px solid #ccc",
                  color: "white",
                }}
                dropdownStyle={{
                  backgroundColor: "black",
                  color: "white",
                  zIndex: 1000,
                }}
              />

              <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
                {error?.phone_no && error?.phone_no[0]}
              </FormHelperText>
              <br />
              <label>Address *</label>
              <input
                type="name"
                className="input p-2 "
                name="address"
                onChange={handleChange}
              />
              <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
                {error?.address && error?.address[0]}
              </FormHelperText>
              <br />
              <input
                type="checkbox"
                required
                checked={isChecked}
                onChange={(e) => setIsChecked(e.target.checked)}
                className="text-white mx-1"
                style={{cursor:"pointer"}}
              />
              <span>
                I agree to the{" "}
                <span
                  className="text-warning"
                  onClick={() => {
                    handleScrollToTop();
                    navigate("/terms");
                  }}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Terms of Use
                </span>{" "}
                &{" "}
                <span
                  className="text-warning"
                  onClick={() => {
                    handleScrollToTop();
                    navigate("/privacy");
                  }}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Privacy Policy
                </span>
              </span>
              <div className="text-center mt-3">
                <Button
                  variant="contained"
                  disabled={isLoading}
                  className="submitBtn fw-bold"
                  onClick={handleSubmit}
                  sx={{ textTransform: "none" }}
                >
                  {isLoading ? (
                    <CircularProgress sx={{ color: "black" }} size={22} />
                  ) : (
                    "Sign up"
                  )}
                </Button>
                <p className="text-center mt-2">
                  Already have an account?{" "}
                  <span
                    className="text-warning"
                    onClick={() => navigate("/login")}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Sign In
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
