import React, { useState } from "react";
import { Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import UploadIcon from "@mui/icons-material/Upload";
import CloseIcon from "@mui/icons-material/Close";

export default function ReturnProduct() {
  const { state } = useLocation();
  const [images, setImages] = useState({
    files: [],
  });

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files); // Convert FileList to array
    setImages((prev) => ({
      ...prev,
      files: [...prev.files, ...newFiles], // Add new files to the existing array
    }));
  };

  const handleRemoveImage = (i) => {
    const RemoveImage = images?.files.filter((val, ind) => ind !== i);
    setImages((prev) => ({
      ...prev,
      files: RemoveImage, // Update the files array with the remaining images
    }));
  };
  const handleSubmit = () => {
    alert(
      "Our support team is reviewing your request. You’ll receive an update with the next steps soon."
    );
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="mainDiv text-white">
        <h3 className="text-center">Return Product</h3>
        <div style={{ width: "95%", margin: "auto", marginTop: "20px" }}>
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <Typography>Order No.</Typography>
              <TextField
                fullWidth
                name="order_no"
                value={state}
                disabled
                InputProps={{
                  sx: {
                    "& .MuiInputBase-input.Mui-disabled": {
                      color: "white",
                    },
                    textAlign: "center", // Center the text
                    fontWeight: "bold",
                    "& .MuiInputBase-input": {
                      textAlign: "center", // Center text inside the input
                    },
                  },
                }}
                sx={{
                  backgroundColor: "#818181",
                  borderRadius: "10px",
                }}
              />
            </div>
          </div>
          <div className="col-lg-12 text-center mt-4">
            <Box>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="upload-button"
                multiple
                type="file"
                onChange={handleFileChange}
              />
              <label htmlFor="upload-button">
                <Tooltip title="Upload Images">
                  <IconButton
                    sx={{
                      backgroundColor: "black", // Black background
                      color: "white", // White icon color
                      borderRadius: "50%", // Circular shape
                      "&:hover": {
                        backgroundColor: "gray", // Optional: lighter gray on hover
                      },
                      width: 50, // Width and height for circle size
                      height: 50,
                    }}
                    color="primary"
                    component="span"
                  >
                    <UploadIcon />
                  </IconButton>
                </Tooltip>
              </label>
            </Box>
            {images?.files?.map((val, index) => (
              <>
                <Box
                  key={index}
                  sx={{
                    position: "relative",
                    display: "inline-block",
                    marginTop: "20px",
                  }}
                >
                  <img
                    src={URL.createObjectURL(val)}
                    alt={``}
                    style={{
                      width: "70px",
                      height: "80px",
                      marginLeft: "10px",
                      marginTop: "14px",
                    }}
                  />
                  <IconButton
                    size="small"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: ".7px",
                      backgroundColor: "black",
                      "&:hover": {
                        backgroundColor: "black",
                      },
                    }}
                    onClick={() => handleRemoveImage(index)}
                  >
                    <CloseIcon fontSize="small" sx={{ color: "white" }} />
                  </IconButton>
                </Box>
              </>
            ))}
          </div>
          <p
            className="text-warning text-end  mx-5"
            onClick={() => navigate("/refund")}
            style={{ cursor: "pointer", textDecoration: "underline" }}
          >
            Refund & Return Policy
          </p>
          <div
            style={{
              backgroundColor: "#393939",
              marginTop: "20px",
              padding: "20px",
              borderRadius: "12px",
            }}
          >
            <h5>Reason for Return</h5>
            <div className="row mt-5">
              <div className="col-lg-4">
                <input type="checkbox" />
                <label className="mx-3">
                  {" "}
                  Price for the product has decreased
                </label>
              </div>
              <div className="col-lg-4">
                <input type="checkbox" />
                <label className="mx-3"> Product quality not as expected</label>
              </div>
              <div className="col-lg-4">
                <input type="checkbox" />
                <label className="mx-3"> Item not as described</label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-4">
                <input type="checkbox" />
                <label className="mx-3"> I have changed my mind</label>
              </div>
              <div className="col-lg-4">
                <input type="checkbox" />
                <label className="mx-3">
                  {" "}
                  I have purchased the product elsewhere
                </label>
              </div>
              <div className="col-lg-4">
                <input type="checkbox" />
                <label className="mx-3"> Product defective or damaged</label>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-lg-4">
                <input type="checkbox" />
                <label className="mx-3"> Wrong item received </label>
              </div>
              <div className="col-lg-4">
                <input type="checkbox" />
                <label className="mx-3"> Missing parts or accessories</label>
              </div>
              <div className="col-lg-4"></div>
            </div>
            <h5 className="mt-5">Others:</h5>
            <textarea
              placeholder="Write Reason"
              rows="10"
              style={{
                width: "100%",
                backgroundColor: "#000",
                padding: "10px",
                border: "none",
                borderRadius: "12px",
                color: "white",
              }}
            ></textarea>
          </div>
          <div className=" text-end">
            <button className="btn btn-warning my-5" onClick={handleSubmit}>
              Submit Request
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
