import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Rating, Skeleton, Typography } from "@mui/material";
import { FaClock, FaLocationDot } from "react-icons/fa6";
import { useNavigate, useParams } from "react-router-dom";
import AllItems from "./ItemsSection";
import AllCategories from "./Categories";
import ScheduleModal from "./scheduleModel";
import {
  GetSellerShopInfo,
  SellerScheduleInfo,
  ShopFollowUnFollow,
} from "../../DAL/SellerShop/SellerShop";
import { useSnackbar } from "notistack";
import { picBaseUrl } from "../../config/config";
import { TiStarFullOutline } from "react-icons/ti";
export default function SellerShop() {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { id } = useParams();
  const [val, setVal] = useState(0);
  const [open, setOpen] = useState(false);
  const [shopData, setShopData] = useState();
  const [value, setValue] = useState(true);
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);

  const handleModelOpen = () => {
    setOpen(true);
  };
  const GetShopInfo = async () => {
    const resp = await GetSellerShopInfo(id);
    if (resp?.status == true) {
      setShopData(resp?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const GetShopSchedule = async () => {
    const resp = await SellerScheduleInfo(id);
    if (resp?.status == true) {
      setSchedule(resp?.data);
    }
  };

  const handleToggleFollow = async () => {
    setValue((prev) => !prev);
    const data = {
      id: id,
      status: value == true ? 1 : 0,
    };
    const resp = await ShopFollowUnFollow(data);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetShopInfo();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (shopData?.shop_name){
      document.title=shopData?.shop_name;
    }
    GetShopInfo();
    GetShopSchedule();
  }, [id,shopData]);
  return (
    <>
      <ScheduleModal open={open} setOpen={setOpen} data={schedule} />
      <div>
        {loading == true ? (
          <Skeleton
            animation="wave"
            variant="rectangular"
            sx={{ width: "100%", height: "400px" }}
          />
        ) : (
          <>
            <div className="heroSection mb-3">
              {shopData?.shop_cover_image && (
                <img
                  src={
                    shopData?.shop_cover_image
                      ? picBaseUrl + shopData?.shop_cover_image
                      : ""
                  }
                  alt=""
                  style={{ width: "100%", objectFit: "cover" }}
                />
              )}

              <div className="hero-content">
                <div className="text-section pt-5">
                  <Typography variant="h3" className="fw-bold text-white">
                    {shopData?.shop_name}
                  </Typography>
                  <Typography variant="body1" className="fw-bold text-white">
                    <FaLocationDot /> {shopData?.shop_location}
                  </Typography>
                  <Chip
                    icon={
                      <FaClock style={{ color: "white", fontSize: "16px" }} />
                    }
                    label="Schedule"
                    sx={{
                      color: "white",
                      backgroundColor: "#6E6054",
                      height: "24px",
                      cursor: "pointer",
                    }}
                    onClick={handleModelOpen}
                  />
                  <div className="d-flex gap-4 mt-3">
                    <Box sx={{ ml: 1, textAlign: "center" }}>
                      <Typography
                        variant="h6"
                        className="text-white d-flex gap-1 justify-content-center"
                        sx={{
                          fontSize: { xs: "15px", sm: "17px", lg: "21px" },
                        }}
                      >
                        {shopData?.total_avg_rating}
                        <TiStarFullOutline
                          style={{ color: "gold", marginTop: "4px" }}
                        />
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "15px", sm: "17px", lg: "21px" },
                        }}
                        className="text-white"
                      >
                        Reviews
                      </Typography>
                    </Box>
                    <Box sx={{ ml: 1, textAlign: "center" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "15px", sm: "17px", lg: "21px" },
                        }}
                        className="text-white"
                      >
                        {shopData?.total_followers}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "15px", sm: "17px", lg: "21px" },
                        }}
                        className="text-white"
                      >
                        Followers
                      </Typography>
                    </Box>
                    <Box sx={{ ml: 1, textAlign: "center" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "15px", sm: "17px", lg: "21px" },
                        }}
                        className="text-white"
                      >
                        {shopData?.products_count}
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{
                          fontSize: { xs: "15px", sm: "17px", lg: "21px" },
                        }}
                        className="text-white"
                      >
                        Products
                      </Typography>
                    </Box>
                    <div>
                      <Button
                        size="small"
                        variant="outlined"
                        sx={{
                          borderColor: "gold",
                          color: "gold",
                          textTransform: "none",
                          marginTop: {
                            lg: "26px",
                            md: "30px",
                            sm: "10px",
                            xs: "10px",
                          },
                        }}
                        className={" outline-btn"}
                        onClick={handleToggleFollow}
                      >
                        {shopData?.is_follow == false ? "Follow" : "Following"}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div
          style={{
            width: "95%",
            position: "relative",
            top: "10rem",
            margin: "auto",
            marginBottom: "12rem",
          }}
        >
          <div
            className="d-flex gap-5"
            style={{ borderBottom: "1px solid grey" }}
          >
            <Typography
              variant="h6"
              className={val == 0 ? "activeItem" : "Item "}
              onClick={() => setVal(0)}
            >
              Items
            </Typography>
            <Typography
              variant="h6"
              className={val == 1 ? "activeItem" : "Item "}
              onClick={() => setVal(1)}
            >
              Catalogs
            </Typography>
          </div>
          <div className="mt-1">
            {val == 0 ? (
              <>
                <div >
                  <AllItems shopId={id} />
                </div>
              </>
            ) : (
              <AllCategories />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
