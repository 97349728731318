import * as React from "react";
import Card from "@mui/material/Card";
// import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
// import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Chip, Skeleton } from "@mui/material";
import { Categories } from "../../DAL/Categories/Category";
import { htmlDecode } from "../../utils/convertHtml";
import { picBaseUrl } from "../../config/config";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";

export default function CategorySlider() {
  const navigate = useNavigate();
  const [data, setData] = React.useState(
    JSON.parse(localStorage.getItem("category_data")) || []
  );
  const [loading, setLoading] = React.useState(true);

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const GetAllCategories = async () => {
    const resp = await Categories();
    if (resp.status === true) {
      localStorage.setItem("category_data", JSON.stringify(resp?.data));
      return resp?.data;
    }
  };

  const { isLoading, isError, error } = useQuery(
    "categoryList",
    GetAllCategories,
    {
      initialData: data,
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      onSuccess: (newData) => {
        setData(newData);
      },
    }
  );

  if (isError) {
    return enqueueSnackbar(error?.message, { variant: "error" });
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <Typography variant="h6" className="text-white">
          Categories
        </Typography>
        <Chip
          label="See All"
          sx={{
            backgroundColor: "black",
            color: "gold",
            cursor: "pointer",
            "&:hover": { backgroundColor: "black" },
          }}
          onClick={() => {
            handleScrollToTop();
            navigate("/categories-listing", { state: data });
          }}
        />
      </div>

      <div className="cardMain1">
        {/* loader */}
        {isLoading &&
          Array.from({ length: 4 }).map(() => (
            <>
              <Skeleton
                sx={{ height: 230, width: 375, borderRadius: "20px" }}
                animation="wave"
                variant="rectangular"
              />
            </>
          ))}

        {/* Api Data */}
        {!isLoading &&
          data?.map((val) => (
            <>
              <div className="cardClassY ">
                <Card
                  className="cardClass h-100"
                  sx={{ minWidth: 365, cursor: "pointer" }}
                  onClick={() => {
                    handleScrollToTop();
                    navigate(`/category-products/${val?.id}`, {
                      state: val?.title,
                    });
                  }}
                >
                  <CardMedia
                    sx={{ height: 175, width: "100%" }}
                    image={picBaseUrl + val?.thumbnail}
                    title=""
                  />
                  <CardContent className="cardContent">
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="text-warning"
                      sx={{
                        whiteSpace: "normal",
                        wordWrap: "breakWord",
                      }}
                    >
                      {val?.title.length > 25
                        ? val?.title.substring(0, 25) + "..."
                        : val?.title}
                    </Typography>
                    <Typography variant="subtitle2">
                      {val?.description === "null" ||
                      val?.description === undefined ||
                      val?.description === null
                        ? ""
                        : htmlDecode(val?.description).substring(0, 50) + "..."}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            </>
          ))}
      </div>
    </>
  );
}
