import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Radio,
  Skeleton,
} from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { Box } from "@mui/system";
import {
  DeletedItem,
  ShowNotification,
} from "../../DAL/Notification/Notification";
import { picBaseUrl } from "../../config/config";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import { useScroll } from "../../hooks/AppContext";

const Notifications = () => {
  const [notifications, setNotifications] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { NoToken } = useScroll();

  const GetNotification = async () => {
    setLoading(true);
    const resp = await ShowNotification();
    if (resp?.status == true) {
      setNotifications(resp?.data);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const resp = await DeletedItem(id);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetNotification();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    NoToken();
    GetNotification();
  }, []);

  return (
    <>
      <div className="mainDiv container">
        {loading === true &&
          Array.from({ length: 4 }).map(() => (
            <>
              <Skeleton
                sx={{
                  height: 100,
                  width: "100%",
                  borderRadius: "10px",
                  marginTop: "10px",
                }}
                animation="wave"
                variant="rectangular"
              />
            </>
          ))}

        {loading == false && (
          <Box
            sx={{
              backgroundColor: "#000000",
              color: "#fff",
              width: "100%",
              margin: "auto",
              padding: "20px",
            }}
          >
            {notifications?.length > 0 && (
              <Typography
                variant="h6"
                align="right"
                sx={{ mb: 2, cursor: "pointer" }}
              >
                Clear All
              </Typography>
            )}
            {notifications?.length == 0 && (
              <h3 className="text-white text-center">
                No Notification Available
              </h3>
            )}
            {notifications?.map((notification) => (
              <>
                <div className="row align-items-center ">
                  <div className="col-10 ">
                    <Card
                      key={notification.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 2,
                        backgroundColor: "#444",
                        color: "#fff",
                        borderRadius: "12px",
                        height: "auto",
                        padding: "2px",
                      }}
                    >
                      <Box
                        component="img"
                        src={picBaseUrl + notification?.product?.thumbnail}
                        alt=""
                        sx={{
                          width: 60,
                          height: 60,
                          borderRadius: "20%",
                          margin: "10px",
                        }}
                      />
                      <CardContent
                        sx={{
                          flexGrow: 1,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <Typography variant="body3" fontWeight="bold">
                            {JSON.parse(notification?.message)?.title}
                          </Typography>

                          <br />
                          <Typography
                            variant="body5"
                            sx={{ color: "lightgray", mb: 1 }}
                          >
                            {JSON.parse(notification?.message)?.body}
                          </Typography>
                        </div>
                        <Typography
                          variant="body5"
                          align="right"
                          sx={{ color: "grey", fontWeight: 600 }}
                        >
                          {moment(notification?.created_at).format(
                            "YYYY-MM-DD"
                          )}
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                  <div className="col-2">
                    <IconButton
                      onClick={() => handleDelete(notification.id)}
                      sx={{ color: "silver" }}
                    >
                      <BackspaceIcon />
                    </IconButton>
                  </div>
                </div>
              </>
            ))}
          </Box>
        )}
      </div>
    </>
  );
};

export default Notifications;
