import React, { useCallback, useEffect, useState } from "react";
import goldlogo from "../../assets/images/Goldlogo.png";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import logoGold from "../../assets/images/logo gold.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { IoBagOutline } from "react-icons/io5";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Badge,
  Button,
  Divider,
  Drawer,
  IconButton,
  styled,
  TextField,
} from "@mui/material";
import { LogoutApi } from "../../DAL/Auth/auth";
import { useSnackbar } from "notistack";
import PersonIcon from "@mui/icons-material/Person";
import CategoryIcon from "@mui/icons-material/Category";
// import StorefrontIcon from "@mui/icons-material/Storefront";
import StoreMallDirectoryIcon from "@mui/icons-material/StoreMallDirectory";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { BsFillCartCheckFill } from "react-icons/bs";
import { CartQuantity } from "../../DAL/Cart/Cart";
import { useScroll } from "../../hooks/AppContext";
import { picBaseUrl } from "../../config/config";
import { debounce } from "lodash";
import NotificationModel from "./NotificationModel";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTranslation } from "react-i18next";

const Navbar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchTerm, setSearchTerm] = useState("");
  const { quantity, handleScrollToTop } = useScroll(true);
  const [hideBadge, setHideBadge] = useState(false);
  const [hideNotificationBadge, setHideNotificationBadge] = useState(false);
  const { notificationQuantity } = useScroll();
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [active, setActive] = useState(1);
  const [isOpen, setIsOpen] = useState(true);
  const [userData, setUserData] = useState();
  const [token, setToken] = useState("");
  const [page, setPage] = useState(1);
  const [open, setOpen] = React.useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const switchLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const StyledBadge = styled(Badge)(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: -3,
      top: 13,
      padding: "0 4px",
    },
  }));

  const handleCartClick = () => {
    handleScrollToTop();
    setHideBadge(true);
    navigate("/cart");
  };
  const handleNotificationClick = () => {
    handleScrollToTop();
    setOpenModel(true);
    setHideNotificationBadge(true);
    navigate("/cart");
  };
  const handleToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  const debouncedFetchData = useCallback(
    debounce((query) => {
      navigate("/search-result", { state: query });
    }, 2000), // Debounce time of 2s
    []
  );

  const handleInputChange = (event) => {
    debouncedFetchData(event.target.value); // Call debounced API fetch
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };
  const handleLogout = async () => {
    setIsNavOpen(false);
    const resp = await LogoutApi();
    if (resp?.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      localStorage.clear();
      navigate("/");
      setToken("");
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    const data = localStorage.getItem("user_data");
    setUserData(JSON.parse(data));
    const tokenData = localStorage.getItem("web_token");
    setToken(tokenData);
    const handleClickOutside = (event) => {
      if (!event.target.closest(".dropdown")) {
        setIsOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <>
      <NotificationModel open={openModel} setOpen={setOpenModel} />
      <div className=" navbar-blur fixed-top">
        <nav className="navbar d-flex navbar-expand-lg ">
          <div className="text-start">
            <MenuIcon
              className="text-white mx-2 "
              onClick={toggleDrawer(true)}
              sx={{ cursor: "pointer" }}
            />
            <Link to={"/"}>
              <img
                src={logoGold}
                alt=""
                className={active === 7 ? "logo-main" : "logo-main"}
                onClick={() => setActive(7)}
                height={40}
              />
            </Link>
          </div>
          <Drawer
            open={open}
            onClose={toggleDrawer(false)}
            sx={{
              width: "100%",
              "& .MuiDrawer-paper": {
                width: { lg: "20%", md: "30%", sm: "40%", xs: "70%" },
                backgroundColor: "black",
              },
            }}
          >
            <ul
              className="navbar-nav d-flex flex-wrap bg-black  pt-3 mb-2 mb-lg-0 px-3 gap-3 "
              onClick={closeNav}
            >
              <div classNvame="text-center">
                <Link to={"/"}>
                  <img
                    src={logoGold}
                    alt=""
                    className={active === 7 ? "logo-main" : "logo-main"}
                    onClick={() => setActive(7)}
                    style={{ width: "80%" }}
                  />
                </Link>
              </div>
              <hr className="text-white" />
              {/* <Link to={"/"} className="link" onClick={handleScrollToTop}> */}
              <li
                className={active === 1 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(1);
                  setOpen(false);
                  navigate("/");
                  handleScrollToTop();
                }}
              >
                Home
              </li>
              {/* </Link> */}

              <li
                onClick={() => setIsReportsOpen((prev) => !prev)}
                className="link"
                style={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                  justifyContent: "space-between",
                  backgroundColor: "#000000",
                  color: "white",
                }}
              >
                Language{" "}
                {isReportsOpen ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </li>
              {isReportsOpen && (
                <>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "8px 15px",
                      color: "white",
                    }}
                    className="link"
                    onClick={() => switchLanguage("en")}
                  >
                    English
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "8px 15px",
                      color: "white",
                    }}
                    className="link"
                    onClick={() => switchLanguage("ur")}
                  >
                    اردو
                  </li>
                  <li
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      padding: "8px 15px",
                      color: "white",
                    }}
                    className="link"
                  >
                    عربي
                  </li>
                </>
              )}

              <li
                className={active === 9 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(9);
                  setOpen(false);
                  navigate("/RingSizeActivity");
                  handleScrollToTop();
                }}
              >
                Measure Ring Size
              </li>

              <li
                className={active === 2 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(2);
                  setOpen(false);
                  navigate("/about");
                  handleScrollToTop();
                }}
              >
                About Us
              </li>

              <li
                className={active === 5 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(5);
                  setOpen(false);
                  navigate("/become-a-supplier");
                  handleScrollToTop();
                }}
              >
                Become a Seller
              </li>

              <li
                className={active === 4 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(4);
                  setOpen(false);
                  navigate("/user-insights");
                  handleScrollToTop();
                }}
              >
                User Insights
              </li>

              <li
                className={active === 3 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(3);
                  setOpen(false);
                  navigate("/blogs");
                  handleScrollToTop();
                }}
              >
                Blogs
              </li>

              <li
                className={active === 6 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(6);
                  setOpen(false);
                  navigate("/gold-rate");
                  handleScrollToTop();
                }}
              >
                Gold Rates
              </li>

              <li
                className={active === 7 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(7);
                  setOpen(false);
                  navigate("/coming");
                  handleScrollToTop();
                }}
              >
                More Apps
              </li>

              <li
                className={active === 8 ? "active link" : "nav-item link"}
                onClick={() => {
                  setActive(8);
                  setOpen(false);
                  navigate("/coming");
                  handleScrollToTop();
                }}
              >
                Future Releases
              </li>
            </ul>
          </Drawer>

          <div
            className={`collapse navbar-collapse ${isNavOpen ? "show" : ""}`}
            id="navbarSupportedContent"
          >
            <TextField
              type="text"
              label="Search "
              onChange={(e) => handleInputChange(e)}
              fullWidth
              size="small"
              sx={{
                width: "50%",
                margin: "auto",
                input: {
                  color: "white",
                  backgroundColor: "#333333",
                  borderRadius: "8px",
                  border: "none",
                }, // Text color for input
                "& .MuiInputLabel-root": {
                  color: "white", // Default label color
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "gold", // Label color when focused
                },
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none", // Removes the default border
                  },
                  "&:hover fieldset": {
                    border: "none", // Removes border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid gold", // Removes border on focus
                  },
                },
                "& .MuiOutlinedInput-root.Mui-focused": {
                  boxShadow: "none", // Removes the blue shadow on focus
                },
              }}
            />
          </div>
          {token ? (
            <>
              <form
                className="d-flex flex-wrap justify-content-center align-items-center gap-1 mt-1"
                role="search"
              >
                {!hideNotificationBadge && notificationQuantity > 0 ? (
                  <Badge
                    badgeContent={notificationQuantity}
                    // anchorOrigin={{
                    //   vertical: "top",
                    //   horizontal: "left",
                    // }}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#ffbf00",
                        justifyContent: "flex-start",
                      },
                    }}
                  >
                    <NotificationsOutlinedIcon
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#333333",
                        borderRadius: "50%",
                        fontSize: "30px",
                        color: "white",
                        padding: "3px",
                      }}
                      onClick={handleNotificationClick}
                    />
                  </Badge>
                ) : (
                  <NotificationsOutlinedIcon
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#333333",
                      borderRadius: "50%",
                      fontSize: "30px",
                      color: "white",
                      padding: "3px",
                    }}
                    onClick={handleNotificationClick}
                  />
                )}
                {!hideBadge && quantity > 0 ? (
                  <Badge
                    badgeContent={quantity}
                    sx={{
                      "& .MuiBadge-badge": {
                        backgroundColor: "#ffbf00",
                        justifyContent: "flex-start",
                      },
                    }}
                  >
                    <LocalMallOutlinedIcon
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#333333",
                        borderRadius: "50%",
                        fontSize: "30px",
                        color: "white",
                        padding: "5px",
                      }}
                      onClick={handleCartClick}
                    />
                  </Badge>
                ) : (
                  <LocalMallOutlinedIcon
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#333333",
                      borderRadius: "50%",
                      fontSize: "30px",
                      color: "white",
                      padding: "5px",
                    }}
                    onClick={handleCartClick}
                  />
                )}
                <Dropdown>
                  <Dropdown.Toggle
                    style={{ backgroundColor: "transparent", border: "none" }}
                    id="dropdown-basic"
                    split
                  >
                    <img
                      src={
                        userData?.image.startsWith("https")
                          ? userData?.image
                          : picBaseUrl + userData?.image
                      }
                      className="profile-image-view"
                      alt=""
                      style={{
                        backgroundColor: "whitesmoke",
                        borderRadius: "50%",
                        border: "1px solid black",
                      }}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      backgroundColor: "#000",
                      width: "300px",
                      padding: "10px",
                    }}
                  >
                    <Dropdown.Item className="text-white custom-dropdown-menu">
                      {userData?.email}
                    </Dropdown.Item>
                    <Divider
                      style={{
                        backgroundColor: "white",
                        borderStyle: "dashed",
                        height: 1,
                      }}
                    />
                    <Dropdown.Item
                      className="text-white custom-dropdown-menu"
                      onClick={() => {
                        handleScrollToTop();
                        navigate("/profile");
                      }}
                    >
                      <PersonIcon /> Profile
                    </Dropdown.Item>
                    <Divider
                      style={{
                        backgroundColor: "grey",
                        // borderStyle: "dashed",
                        height: 1,
                      }}
                    />
                    <Dropdown.Item
                      className="text-white custom-dropdown-menu"
                      onClick={() => {
                        handleScrollToTop();
                        navigate("/favorites");
                      }}
                    >
                      <CategoryIcon /> Favourite Products
                    </Dropdown.Item>
                    <Divider
                      style={{
                        backgroundColor: "grey",
                        height: 1,
                      }}
                    />
                    <Dropdown.Item
                      className="text-white custom-dropdown-menu"
                      onClick={() => {
                        handleScrollToTop();
                        navigate("/followed-shops");
                      }}
                    >
                      <StoreMallDirectoryIcon /> Followed Shops
                    </Dropdown.Item>
                    <Divider
                      style={{
                        backgroundColor: "grey",
                        height: 1,
                      }}
                    />
                    <Dropdown.Item
                      className="text-white custom-dropdown-menu"
                      onClick={() => {
                        handleScrollToTop();
                        navigate("/my-order");
                      }}
                    >
                      <LocalMallIcon /> My Orders
                    </Dropdown.Item>
                    <Divider
                      style={{
                        backgroundColor: "grey",
                        height: 1,
                      }}
                    />
                    <Dropdown.Item
                      className="text-white custom-dropdown-menu"
                      onClick={handleLogout}
                    >
                      <LogoutIcon /> Logout
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </form>
            </>
          ) : (
            <Button
              variant="outlined"
              className="outline-btn fw-bold"
              onClick={() => navigate("/pre-login")}
              sx={{
                color: "#FFC107",
                borderColor: "#FFC107",
                textTransform: "none",
                marginRight: "10px",
              }}
            >
              Sign In
            </Button>
          )}
        </nav>
        <div className="text-center drawer-button pb-3">
          <TextField
            type="text"
            label="Search "
            onChange={(e) => handleInputChange(e)}
            fullWidth
            size="small"
            sx={{
              width: "95%",
              margin: "auto",
              input: { color: "white" }, // Text color for input
              "& .MuiInputLabel-root": {
                color: "white", // Default label color
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: "white", // Label color when focused
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "silver", // Default border color
                  borderRadius: "8px",
                },
                "&:hover fieldset": {
                  borderColor: "silver", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "silver", // Border color on focus
                },
              },
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Navbar;
