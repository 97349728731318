import { invokeApi } from "../../utils";

export const TopTrendings = async () => {
  const requestObj = {
    path: `/api/web/user/trending/product/`,
    method: "GET",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const AllTrendingProducts = async () => {
  const requestObj = {
    path: `/api/user/trending/all`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};
