import React, { useState } from "react";
import { Link } from "react-router-dom";
import goldlogo from "../../assets/images/Goldlogo.png";
import { SlSocialInstagram } from "react-icons/sl";
import { FaFacebookSquare } from "react-icons/fa";
import { AiFillLinkedin } from "react-icons/ai";
import { FaXTwitter } from "react-icons/fa6";

const Footer = () => {
  const [active, setActive] = useState();

  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="footer mt-5" style={{ backgroundColor: "#000000" }}>
      <div className="container mt-5 pt-5 pb-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 ">
            <img src={goldlogo} alt="" height={120} width={150} />
            <p className="text-white mt-4 ">
              Your Trusted Online Marketplace for Buying and Selling Premium
              Gold Products.
            </p>
            <div className="text-white d-flex footer flex-row gap-3 mt-4">
              <h3>
                <SlSocialInstagram />
              </h3>
              <h3>
                <FaFacebookSquare />
              </h3>
              <h3>
                <AiFillLinkedin />
              </h3>
              <h3>
                <FaXTwitter />
              </h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6  pt-3 text-white">
            <ul style={{ listStyle: "none", paddingLeft: 0 }}>
              <li style={{ fontSize: "17px", fontWeight: "bold" }}>
                Quick Links
              </li>
              <div
                className="my-3"
                style={{ border: "3px solid #F6BA28", width: "25%" }}
              ></div>
              <Link
                to="/privacy"
                className="design1"
                onClick={handleScrollToTop}
              >
                <li
                  className={active === 1 ? "active" : "after-active"}
                  onClick={() => setActive(1)}
                >
                  Privacy Policy
                </li>
              </Link>
              <Link
                to="/refund"
                className="design1"
                onClick={handleScrollToTop}
              >
                <li
                  className={active === 2 ? "active" : "after-active"}
                  onClick={() => setActive(2)}
                >
                  Refund & Return Policy
                </li>
              </Link>
              <Link
                to="/shipping"
                className="design1"
                onClick={handleScrollToTop}
              >
                <li
                  className={active === 3 ? "active" : "after-active"}
                  onClick={() => setActive(3)}
                >
                  Shipping Policy
                </li>
              </Link>
              <Link to="/terms" className="design1" onClick={handleScrollToTop}>
                <li
                  className={active === 4 ? "active" : "after-active"}
                  onClick={() => setActive(4)}
                >
                  Terms of Use
                </li>
              </Link>
              <Link to="/faq" className="design1" onClick={handleScrollToTop}>
                <li
                  className={active === 5 ? "active" : "after-active"}
                  onClick={() => setActive(5)}
                >
                  FAQs
                </li>
              </Link>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 pt-3 text-white">
            <ul style={{ listStyle: "none", paddingLeft: 0 }}>
              <li style={{ fontSize: "17px", fontWeight: "bold" }}>Company</li>
              <div
                className="my-3"
                style={{ border: "3px solid #F6BA28", width: "25%" }}
              ></div>
              <Link to="/about" className="design1" onClick={handleScrollToTop}>
                <li
                  className={active === 6 ? "active" : "after-active"}
                  onClick={() => setActive(6)}
                >
                  About us
                </li>
              </Link>
              <Link to="/blogs" className="design1" onClick={handleScrollToTop}>
                <li
                  className={active === 7 ? "active" : "after-active"}
                  onClick={() => setActive(7)}
                >
                  Blogs
                </li>
              </Link>
            </ul>
          </div>
          <div className="col-lg-2 col-md-6 pt-3 text-white">
            <ul style={{ listStyle: "none", paddingLeft: 0 }}>
              <li style={{ fontSize: "17px", fontWeight: "bold" }}>
                Contact Us
              </li>
              <div
                className="my-3"
                style={{ border: "3px solid #F6BA28", width: "25%" }}
              ></div>
              <a
                href="https://wa.me/+923004425678"
                style={{ color: "white", fontSize: "15px" }}
              >
                +923004425678
              </a>{" "}
              <br />
              <a
                href="mailto:support@goldbazaar.com"
                style={{ color: "white", fontSize: "15px" }}
              >
                support@goldbazaar.shop
              </a>
            </ul>
          </div>
        </div>
      </div>
      <div className=" pb-4 ">
        <hr
          style={{ border: "2px solid white", width: "99%", margin: "auto" }}
        />
        <p className="after-active text-center mt-5">
          Copyright © 2024 Gold Bazaar. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
