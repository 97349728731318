import { invokeApi } from "../../utils";

export const WebFeedBackApi = async (data) => {
  const requestObj = {
    path: `/api/web/feedback/save`,
    method: "POST",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
