import { invokeApi } from "../../utils";

export const CartListing = async (id) => {
  const requestObj = {
    path: `/api/user/product/cart/list`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const UpdateQuantity = async (data) => {
  const requestObj = {
    path: `/api/user/cart/quantity/update`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData:data
  };
  return invokeApi(requestObj);
};

export const DeleteItem = async (id) => {
    const requestObj = {
      path: `/api/user/product/cart/remove/${id}`,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("web_token")}`,
      },
    };
    return invokeApi(requestObj);
  };

  export const CartQuantity = async (id) => {
    const requestObj = {
      path: `/api/user/product/cart/count`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("web_token")}`,
      },
    };
    return invokeApi(requestObj);
  };