import { invokeApi } from "../../utils";

export const ShowNotification = async (id) => {
  const requestObj = {
    path: `/api/user/notification/`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const GetNotification = async (id) => {
  const requestObj = {
    path: `/api/user/notification/count`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const DeletedItem = async (id) => {
  const requestObj = {
    path: `/api/user/notification/delete/${id}`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const DeletedAllItems = async () => {
  const requestObj = {
    path: `/api/user/notification/clear`,
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const ReadNotification = async (data) => {
  const requestObj = {
    path: `/api/user/notification/read`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData:data
  };
  return invokeApi(requestObj);
};