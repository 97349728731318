// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBvze65XWNPzcTIN51_aLHm0WIxkGjdnuI",
  authDomain: "goldstore-2ec22.firebaseapp.com",
  projectId: "goldstore-2ec22",
  storageBucket: "goldstore-2ec22.appspot.com",
  messagingSenderId: "371590429675",
  appId: "1:371590429675:web:751cd0c145f2f280d937ae",
  measurementId: "G-1JL30Y8HVW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const auth = getAuth();

export default app;
