import React, { useState } from "react";
import logo from "../../assets/images/Goldlogo.png";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/material/styles";
import { RxCross2 } from "react-icons/rx";
import { ResetPassword } from "../../DAL/Auth/auth";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#181818",
  boxShadow:
    "0px 4px 10px #534005, 4px 0px 10px #534005, -4px 0px 10px #534005, 0px -4px 10px #534005",
  p: 4,
  animation: "slideDown 0.5s ease",
  borderRadius: "12px",
};

const BlurBackdrop = styled("div")({
  backdropFilter: "blur(8px)",
});

const globalStyles = `
@keyframes slideDown {
  from {
    transform: translate(-50%, -60%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}
`;

const NewPassword = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [error, setError] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleClose = () => setOpen(false);
  const [inputs, setInputs] = useState({
    password: "",
    confirm_password: "",
  });
  console.log(inputs, "lksdja");
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInputs((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (inputs?.password !== inputs?.confirm_password) {
      enqueueSnackbar("New Password and confirm password does not match", {
        variant: "error",
      });
      return;
    }
    setIsLoading(true);
    const formData = new FormData();
    formData.append("email", state);
    formData.append("password", inputs?.password);
    formData.append("password_confirmation", inputs?.confirm_password);

    const resp = await ResetPassword(formData);

    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/login");
    } else {
      setIsLoading(false);
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setError(resp?.message);
        enqueueSnackbar("Please fill the required fileds", {
          variant: "error",
        });
      }
    }
  };
  return (
    <>
      <div className="text-center text-white" style={{ height: "100vh" }}>
        <img src={logo} alt="" width={130} height={100} className="mt-4" />
        <div className="Sign-up mt-5 p-5">
          <h3 className="text-center">Create New Password</h3>
          <div className="text-start">
            <div>
              <label>New Password *</label>
            </div>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              name="password"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassword((prev) => !prev)}
                      sx={{
                        color: "white",
                      }}
                    >
                      {showPassword ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  color: "white",
                  "&.Mui-focused": {
                    color: "gold",
                  },
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "gold",
                  },
                },
              }}
            />
            <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
              {error?.password && error?.password[0]}
            </FormHelperText>
            <br />
            <div className="d-flex justify-content-between">
              <label>Confirm Password *</label>
            </div>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPass ? "text" : "password"}
              name="confirm_password"
              onChange={handleChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPass((prev) => !prev)}
                      sx={{
                        color: "white",
                      }}
                    >
                      {showPass ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  color: "white",
                  "&.Mui-focused": {
                    color: "gold",
                  },
                },
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  color: "white",
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "gold",
                  },
                },
              }}
            />
            <FormHelperText className="text-danger" sx={{ fontSize: "10px" }}>
              {error?.confirm_password && error?.confirm_password[0]}
            </FormHelperText>
            <br />
            <div className="text-center mt-3">
              <Button
                variant="contained"
                fullWidth
                disabled={isLoading}
                className="submitBtn fw-bold"
                size="large"
                onClick={handleSubmit}
                sx={{ textTransform: "none" }}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "black" }} size={22} />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">
        <style>{globalStyles}</style>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            component: BlurBackdrop,
          }}
        >
          <div className="text-center">
            <Box sx={style}>
              <div className="text-end">
                <RxCross2 className="text-white h3" />
              </div>
              <Typography id="modal-modal-title" variant="h1" component="h1">
                <FaCheckCircle style={{ color: "#D5AD3B" }} />
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                className="text-white"
                sx={{ mt: 2 }}
              >
                Sign in Successful
              </Typography>
              <Typography
                id="modal-modal-description"
                className="text-secondary"
                sx={{ mt: 2 }}
              >
                Please Wait......
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h3"
                component="h3"
                className="text-white"
                sx={{ mt: 2 }}
              >
                <FaRegCircle style={{ color: "#D5AD3B" }} />
              </Typography>
            </Box>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default NewPassword;
