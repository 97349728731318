// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import Avatar from "@mui/material/Avatar";
// import Rating from "@mui/material/Rating";
// import { ImQuotesLeft } from "react-icons/im";
// import ReviewsDetails from "./ReviewsDetails";

// function Reviews() {
//   function SampleNextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//       <div
//         className={className}
//         style={{
//           ...style,
//           display: "block",
//           background: "none",
//           marginRight: "3rem",
//         }}
//         onClick={onClick}
//       />
//     );
//   }
//   const settings = {
//     infinite: false,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     autoplay: true,
//     speed: 4000,
//     autoplaySpeed: 4000,
//     cssEase: "linear",
//     nextArrow: <SampleNextArrow />,
//     // rtl: true,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 3,
//           infinite: true,
//           dots: true,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 2,
//           initialSlide: 2,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };
//   return (
//     <>
//       <div className="text-center  mb-5 mainDiv">
//         <h1 className="text-warning mt-2">Reviews</h1>
//         <h3 className="text-white">Meet Our Happy Customer</h3>
//       </div>
//       <div className="container">
//         <div className="slider-container card-deck  text-white">
//           <Slider {...settings}>
//             <div
//               className="card container-card bg-black  text-white "
//               style={{ width: "20rem" }}
//             >
//               <h2 className="text-warning">
//                 <ImQuotesLeft />
//               </h2>
//               <div className="card-body">
//                 <p className="card-text">
//                   Another waste shoulder loss any intersection new any stand. A
//                   solutionize while before they language. Hear club email
//                   first-order keywords enable. Uat pups kimono yet with board
//                   socialize. Thought spaces now company seat my.
//                 </p>
//               </div>
//               <div className="d-flex container">
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
//                 <div className="mx-2">
//                   <p>David Shakeel</p>
//                   <Rating
//                     name="half-rating-read"
//                     defaultValue={4}
//                     precision={0.5}
//                     readOnly
//                   />
//                 </div>
//               </div>
//             </div>
//             <div
//               className="card container-card bg-black  text-white"
//               style={{ width: "25rem"}}
//             >
//               <h1 className="text-warning">
//                 <ImQuotesLeft />
//               </h1>
//               <div className="card-body">
//                 <p className="card-text">
//                   Another waste shoulder loss any intersection new any stand. A
//                   solutionize while before they language. Hear club email
//                   first-order keywords enable. Uat pups kimono yet with board
//                   socialize. Thought spaces now company seat my.
//                 </p>
//               </div>
//               <div className="d-flex container">
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
//                 <div className="mx-2">
//                   <p>David Shakeel</p>
//                   <Rating
//                     name="half-rating-read"
//                     defaultValue={4}
//                     precision={0.5}
//                     readOnly
//                   />
//                 </div>
//               </div>
//             </div>
//             <div
//               className="card container-card bg-black text-white"
//               style={{ width: "25rem" }}
//             >
//               <h1 className="text-warning">
//                 <ImQuotesLeft />
//               </h1>
//               <div className="card-body">
//                 <p className="card-text">
//                   Another waste shoulder loss any intersection new any stand. A
//                   solutionize while before they language. Hear club email
//                   first-order keywords enable. Uat pups kimono yet with board
//                   socialize. Thought spaces now company seat my.
//                 </p>
//               </div>
//               <div className="d-flex container">
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
//                 <div className="mx-2">
//                   <p>David Shakeel</p>
//                   <Rating
//                     name="half-rating-read"
//                     defaultValue={4}
//                     precision={0.5}
//                     readOnly
//                   />
//                 </div>
//               </div>
//             </div>
//             <div
//               className="card container-card bg-black text-white"
//               style={{ width: "25rem" }}
//             >
//               <h1 className="text-warning">
//                 <ImQuotesLeft />
//               </h1>
//               <div className="card-body">
//                 <p className="card-text">
//                   Another waste shoulder loss any intersection new any stand. A
//                   solutionize while before they language. Hear club email
//                   first-order keywords enable. Uat pups kimono yet with board
//                   socialize. Thought spaces now company seat my.
//                 </p>
//               </div>
//               <div className="d-flex container">
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
//                 <div className="mx-2">
//                   <p>David Shakeel</p>
//                   <Rating
//                     name="half-rating-read"
//                     defaultValue={4}
//                     precision={0.5}
//                     readOnly
//                   />
//                 </div>
//               </div>
//             </div>
//             <div
//               className="card container-card bg-black text-white"
//               style={{ width: "25rem" }}
//             >
//               <h1 className="text-warning">
//                 <ImQuotesLeft />
//               </h1>
//               <div className="card-body">
//                 <p className="card-text">
//                   Another waste shoulder loss any intersection new any stand. A
//                   solutionize while before they language. Hear club email
//                   first-order keywords enable. Uat pups kimono yet with board
//                   socialize. Thought spaces now company seat my.
//                 </p>
//               </div>
//               <div className="d-flex container">
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
//                 <div className="mx-2">
//                   <p>David Shakeel</p>
//                   <Rating
//                     name="half-rating-read"
//                     defaultValue={4}
//                     precision={0.5}
//                     readOnly
//                   />
//                 </div>
//               </div>
//             </div>
//             <div
//               className="card container-card bg-black  text-white"
//               style={{ width: "25rem" }}
//             >
//               <h1 className="text-warning">
//                 <ImQuotesLeft />
//               </h1>
//               <div className="card-body">
//                 <p className="card-text">
//                   Another waste shoulder loss any intersection new any stand. A
//                   solutionize while before they language. Hear club email
//                   first-order keywords enable. Uat pups kimono yet with board
//                   socialize. Thought spaces now company seat my.
//                 </p>
//               </div>
//               <div className="d-flex container">
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
//                 <div className="mx-2">
//                   <p>David Shakeel</p>
//                   <Rating
//                     name="half-rating-read"
//                     defaultValue={4}
//                     precision={0.5}
//                     readOnly
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="card container-card bg-black text-white">
//               <h1 className="text-warning">
//                 <ImQuotesLeft />
//               </h1>
//               <div className="card-body">
//                 <p className="card-text">
//                   Another waste shoulder loss any intersection new any stand. A
//                   solutionize while before they language. Hear club email
//                   first-order keywords enable. Uat pups kimono yet with board
//                   socialize. Thought spaces now company seat my.
//                 </p>
//               </div>
//               <div className="d-flex container">
//                 <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
//                 <div className="mx-2">
//                   <p>David Shakeel</p>
//                   <Rating
//                     name="half-rating-read"
//                     defaultValue={4}
//                     precision={0.5}
//                     readOnly
//                   />
//                 </div>
//               </div>
//             </div>
//           </Slider>
//         </div>
//       </div>
//       {/* ---------------------------------Feedback---------------------------------------- */}
//       <ReviewsDetails />
//     </>
//   );
// }

// export default Reviews;


import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Avatar from "@mui/material/Avatar";
import Rating from "@mui/material/Rating";
import { ImQuotesLeft } from "react-icons/im";
import ReviewsDetails from "./ReviewsDetails";

function Reviews() {
  const reviewsData = [
    {
      id: 1,
      text: "Another waste shoulder loss any intersection new any stand. A solutionize while before they language. Hear club email first-order keywords enable.",
      name: "David Shakeel",
      rating: 4,
      avatar: "/static/images/avatar/1.jpg",
    },
    {
      id: 2,
      text: "Another waste shoulder loss any intersection new any stand. A solutionize while before they language. Hear club email first-order keywords enable.",
      name: "David Shakeel",
      rating: 4,
      avatar: "/static/images/avatar/1.jpg",
    },
    {
      id: 3,
      text: "Another waste shoulder loss any intersection new any stand. A solutionize while before they language. Hear club email first-order keywords enable.",
      name: "David Shakeel",
      rating: 4,
      avatar: "/static/images/avatar/1.jpg",
    },
    {
      id: 4,
      text: "Another waste shoulder loss any intersection new any stand. A solutionize while before they language. Hear club email first-order keywords enable.",
      name: "David Shakeel",
      rating: 4,
      avatar: "/static/images/avatar/1.jpg",
    },
    {
      id: 5,
      text: "Another waste shoulder loss any intersection new any stand. A solutionize while before they language. Hear club email first-order keywords enable.",
      name: "David Shakeel",
      rating: 4,
      avatar: "/static/images/avatar/1.jpg",
    },
    {
      id: 6,
      text: "Another waste shoulder loss any intersection new any stand. A solutionize while before they language. Hear club email first-order keywords enable.",
      name: "David Shakeel",
      rating: 4,
      avatar: "/static/images/avatar/1.jpg",
    },
    {
      id: 7,
      text: "Another waste shoulder loss any intersection new any stand. A solutionize while before they language. Hear club email first-order keywords enable.",
      name: "David Shakeel",
      rating: 2,
      avatar: "/static/images/avatar/1.jpg",
    },
    
  ];

  const settings = {
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 4000,
    cssEase: "linear",
    nextArrow: <SampleNextArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "none",
          marginRight: "3rem",
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <>
      <div className="text-center mb-5 mainDiv">
        <h1 className="text-warning mt-2">Reviews</h1>
        <h3 className="text-white">Meet Our Happy Customers</h3>
      </div>
      <div className="container">
        <div className="slider-container text-white">
          <Slider {...settings}>
            {reviewsData.map((review) => (
              <div
                key={review.id}
                className="card container-card  text-white "
                style={{ width: "25rem", }} 
              >
                <h1 className="text-warning">
                  <ImQuotesLeft />
                </h1>
                <div className="card-body">
                  <p className="card-text">{review.text}</p>
                </div>
                <div className="d-flex container">
                  <Avatar alt={review.name} src={review.avatar} />
                  <div className="mx-2">
                    <p>{review.name}</p>
                    <Rating
                      name="half-rating-read"
                      defaultValue={review.rating}
                      precision={0.5}
                      readOnly
                    />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      {/* ---------------------------------Feedback---------------------------------------- */}
      <ReviewsDetails />
    </>
  );
}

export default Reviews;
