// here we add all base urls and keys

// Base URLS
export const baseUri = process.env.REACT_APP_API_BASE_URL;

export const picBaseUrl = "https://goldbazar.shop/backend/public/";

export const socketUrl = process.env.REACT_APP_SOCKET_URL;

// export const picBaseUrl = process.env.REACT_APP_PIC_BASE_URL;
// Keysh
// export const secret_key = process.env.REACT_APP_API_SECRET_KEY;
