import React, { useState } from "react";
import logo from "../../assets/images/Goldlogo.png";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import {
  Button,
  CircularProgress,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ForgetPasswordApi } from "../../DAL/Auth/auth";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("email", email);
    const resp = await ForgetPasswordApi(formData);
    if (resp?.status == true) {
      localStorage.setItem("new-email", email);
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/otp-verfication", { state: email });
    } else {
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        setError(resp?.message);
        enqueueSnackbar("Please fill the required field", { variant: "error" });
      }

      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className="container m-auto"
        style={{ paddingTop: "130px", height: "100vh" }}
      >
        <div className="text-center text-white mb-3">
          <img src={logo} alt="" width={130} height={100} />
        </div>
        <div className="row ">
          <div className="col-lg-4"></div>
          <div className="col-lg-4">
            <Typography variant="h5" className="text-white mb-3">
              <KeyboardBackspaceIcon
                onClick={() => navigate(-1)}
                sx={{ cursor: "pointer", marginRight: "10px" }}
              />
              Reset Password
            </Typography>
            {/* <Typography className="text-secondary">
              Please enter your email we will send an otp code in the next step
              to reset your password{" "}
            </Typography> */}
            <form onSubmit={handleSubmit} className="mt-3">
              <TextField
                type="email"
                required
                label="Enter Email "
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                sx={{
                  input: { color: "white" }, // Text color for input
                  "& .MuiInputLabel-root": {
                    color: "white", // Default label color
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "gold", // Label color when focused
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "silver", // Default border color
                      borderRadius: "13px",
                    },
                    "&:hover fieldset": {
                      borderColor: "silver", // Border color on hover
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "gold", // Border color on focus
                    },
                  },
                }}
              />
              <FormHelperText className="text-danger" sx={{ fontSize: "13px" }}>
                {error?.email && error?.email[0]}
              </FormHelperText>
              <div className="text-end mt-3">
                <div className="text-center mt-3">
                  <Button
                    variant="contained"
                    disabled={isLoading}
                    className="submitBtn fw-bold"
                    type="submit"
                    sx={{ textTransform: "none" }}
                  >
                    {isLoading ? (
                      <CircularProgress size={22} sx={{ color: "black" }} />
                    ) : (
                      "Continue"
                    )}
                  </Button>
                </div>
              </div>
            </form>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
