import {
  Box,
  Button,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ForgetPasswordApi, OtpVerifyApi } from "../../DAL/Auth/auth";
import { enqueueSnackbar } from "notistack";
import logo from "../../assets/images/Goldlogo.png";

const OtpInput = styled("input")({
  width: "2rem",
  height: "2rem",
  margin: "0.5rem",
  fontSize: "1.5rem",
  textAlign: "center",
  borderRadius: "4px",
  border: "1px solid #ccc",
});
const OTP = ({ separator, length, value, onChange }) => {
  const inputRefs = useRef(new Array(length).fill(null));

  const handleChange = (e, index) => {
    const { value: inputValue } = e.target;
    if (inputValue.length > 1) return; // Prevent entering more than one digit

    const newValue = [...value];
    newValue[index] = inputValue;
    onChange(newValue.join(""));

    // Focus the next input
    if (inputValue && index < length - 1) {
      inputRefs.current[index + 1].focus();
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {Array.from({ length }).map((_, index) => (
          <React.Fragment key={index}>
            <OtpInput
              type="text"
              maxLength="1"
              ref={(el) => (inputRefs.current[index] = el)}
              value={value[index] || ""}
              onChange={(e) => handleChange(e, index)}
            />
            {index < length - 1 && separator}
          </React.Fragment>
        ))}
      </div>
    </>
  );
};
export default function OtpVerification() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState(5 * 60);
  const [email, setEmail] = useState("");

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("email", email);
    const resp = await ForgetPasswordApi(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      setTime(5 * 60);
    } else {
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        enqueueSnackbar("Please fill the required field", { variant: "error" });
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const formData = new FormData();
    formData.append("email", state);
    formData.append("otp", otp);
    const resp = await OtpVerifyApi(formData);
    if (resp.status == true) {
      localStorage.removeItem("new-email");
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate("/new-password", { state: state });
    } else {
      setIsLoading(false);
      if (typeof resp?.message == "string") {
        enqueueSnackbar(resp?.message, { variant: "error" });
      } else {
        enqueueSnackbar(resp?.message?.otp[0], { variant: "error" });
      }
    }
  };

  useEffect(() => {
    const resp = localStorage.getItem("new-email");
    setEmail(resp);
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => {
          const newTime = prevTime - 1;
          localStorage.setItem("remainingTime", newTime);
          return newTime;
        });
      }, 1000);

      return () => clearInterval(timer); // Cleanup the interval
    }

    if (time <= 0) {
      // When time reaches 0, remove it from localStorage
      localStorage.removeItem("remainingTime");
    }
  }, [time]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  return (
    <>
      <div className="row container m-auto">
        <div className="col-12">
          <div className="row">
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
                paddingTop: "6rem",
                textAlign: "center",
                height: "100vh",
                color: "white",
              }}
            >
              <div className="text-center text-white">
                <img src={logo} alt="" width={130} height={100} />
              </div>

              <Typography variant="h5">
                <KeyboardBackspaceIcon
                  onClick={() => navigate(-1)}
                  sx={{ cursor: "pointer", marginRight: "10px" }}
                />
                Verify OTP
              </Typography>
              <h4>{formatTime(time)}</h4>
              <h6 className="text-center" style={{ width: "40%" }}>
                We have just sent a 6 digit code to your email{" "}
                <span className="fw-bold">{email}</span> <br />
                Please check and enter the OTP below.
              </h6>
              <OTP value={otp.split("")} onChange={setOtp} length={6} />
              <Button
                variant="contained"
                disabled={isLoading}
                className="submitBtn fw-bold"
                onClick={handleSubmit}
                sx={{ textTransform: "none" }}
              >
                {isLoading ? (
                  <CircularProgress sx={{ color: "black" }} size={22} />
                ) : (
                  "Continue"
                )}
              </Button>

              <Typography
                variant="body2"
                className="mt-1"
                sx={{ mt: { md: -2 } }}
              >
                Didn't receive any OTP? {""}
                <Link
                  variant="subtitle2"
                  className={`Forget-Link text-warning ${
                    time == 0 ? "" : "disabled"
                  }`}
                  style={{ cursor: time == 0 ? "pointer" : "not-allowed" }}
                  onClick={time > 0 ? "" : handleSubmitEmail} // Disable click when time is 0
                  disabled={time > 0} // Disable the link when time is 0
                >
                  Resend
                </Link>
              </Typography>
            </Box>
          </div>
        </div>
      </div>
    </>
  );
}
