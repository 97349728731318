import React from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Card, CardContent, Typography, CardHeader } from "@mui/material";
import { PiHandshakeFill } from "react-icons/pi";
import { Ri24HoursFill } from "react-icons/ri";
import { FaPeopleGroup } from "react-icons/fa6";
import { SiLintcode } from "react-icons/si";
import { FaUserEdit } from "react-icons/fa";
import { FaHandHoldingUsd } from "react-icons/fa";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
}));

const Cards = () => {
  const classes = useStyles();
  const data = [
    {
      icon: <SiLintcode />,
      cardtitle: "Reach",
      cardtext:
        "Our app is dedicated to bringing you the best deals and promotions on gold jewelry. With new offers added regularly, there’s always something exciting to discover.",
    },
    {
      icon: <FaUserEdit />,
      cardtitle: "Free Registration",
      cardtext:
        "We partner with reputable sellers who are committed to providing exceptional products and services. Each seller undergoes a thorough vetting process to ensure they meet our high standards of quality and reliability.",
    },
    {
      icon: <PiHandshakeFill />,
      cardtitle: "Reliable Shipping",
      cardtext:
        "Your security is our top priority. Gold Bazaar uses advanced encryption and secure payment gateways to protect your personal and financial information. Shop with confidence knowing your data is safe with us.",
    },
    {
      icon: <Ri24HoursFill />,
      cardtitle: "Timely Payment",
      cardtext:
        "Our dedicated customer support team is always here to assist you. Whether you have questions about a product, need help with your order, or have any other inquiries, we are just a click away.",
    },
    {
      icon: <FaHandHoldingUsd />,
      cardtitle: "Sell Across Pakistan",
      cardtext:
        "Make informed purchasing decisions with detailed product descriptions, high-resolution images, and customer reviews. Learn everything you need to know about a product before making your purchase.",
    },
    {
      icon: <FaPeopleGroup />,
      cardtitle: "Reach Millions of Customer",
      cardtext:
        "Your security is our top priority. Gold Bazaar uses advanced encryption and secure payment gateways to protect your personal and financial information. Shop with confidence knowing your data is safe with us.",
    },
  ];

  return (
    <div className={classes.root}>
    <h3 className="text-warning text-center my-5">Why Choose Gold Bazar to Sell?</h3>
      <div style={{ width: "90%", margin: "auto" }}>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
        >
          {data.map((elem, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} style={{ display: "flex" }}>
                <Card
                  className="card card1 h-100"
                  sx={{
                    backgroundColor: "#161616",
                    color: "white",
                    borderRadius: "36px",
                    flexGrow: 1,
                  }}
                >
                  <Typography
                    variant="h2"
                    style={{
                      backgroundColor: "black",
                      color: "#FFBF00",
                      height: "80px",
                      width: "80px",
                      margin: "20px",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {elem.icon}
                  </Typography>
                  <CardHeader
                    style={{ color: "#655622" }}
                    title={elem.cardtitle}
                  />
                  <CardContent>
                    <Typography variant="p" gutterBottom>
                      {elem.cardtext}
                    </Typography>
                  </CardContent>
                </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default Cards;
