import React, { useEffect, useState } from "react";
import { FaPenSquare } from "react-icons/fa";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { RxCross2 } from "react-icons/rx";
import { Box, styled, width } from "@mui/system";
import OTPInput from "./Otp";
import { useNavigate } from "react-router-dom";
import { GetProfile } from "../../DAL/Profile/profile";
import moment from "moment/moment";
import { picBaseUrl } from "../../config/config";
import { Skeleton, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";

const Profile = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [email, setEmail] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState("");
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);

  const BlurBackdrop = styled("div")({
    backdropFilter: "blur(8px)",
  });

  const handleClose = () => setOpen(false);

  const handleProceed = () => {
    if (!email) {
      enqueueSnackbar("Email field is required", { variant: "error" });
      return;
    }
    const emailRegex = /\S+@\S+\.\S+/;
    if (emailRegex.test(email)) {
      const generatedOtp = Math.floor(
        100000 + Math.random() * 900000
      ).toString();

      setOtp(generatedOtp);
      setShowOtpInput(true);
    } else {
      enqueueSnackbar("Please enter valid email", { variant: "error" });
    }
  };

  const getProfileData = async () => {
    setLoading(true);
    const resp = await GetProfile();
    if (resp?.status == true) {
      setUserData(resp?.data);
      setLoading(false);
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#282c34",
    boxShadow:
      "0px 4px 10px #534005, 4px 0px 10px #534005, -4px 0px 10px #534005, 0px -4px 10px #534005",
    p: 4,
    width: 365,
    borderRadius: "10px",
  };

  const handleCloseModel = () => {
    handleClose();
    setShowOtpInput(false);
  };

  useEffect(() => {
    getProfileData();
  }, []);
  return (
    <>
      <div className="container text-white mainDiv ">
        <div className="container text-center pt-2">
          <div className="text-end">
            <button
              className="btn btn-warning fw-bold"
              onClick={() => navigate("/profile-edit", { state: userData })}
            >
              Edit
            </button>
          </div>
          {loading == true ? (
            <Box>
              <Skeleton
                animation="wave"
                variant="circle"
                sx={{
                  height: 95,
                  width: 95,
                  borderRadius: "50%",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <Skeleton
                animation="wave"
                variant="rectangular"
                sx={{
                  height: 20,
                  width: 95,
                  borderRadius: "5px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "5px",
                }}
              />
            </Box>
          ) : (
            <>
              <img
                src={
                  userData?.image.startsWith("https")
                    ? userData?.image
                    : picBaseUrl + userData?.image || "dummy2.jpg"
                }
                alt="Profile"
                className="img-upload"
                style={{
                  height: "100px",
                  width: "100px",
                  backgroundColor: "white",
                  borderRadius: "50%",
                  border: "2px solid gold",
                  objectFit: "cover",
                }}
              />

              <br />
              <label> {userData?.username}</label>
              <p
                className="text-secondary text-center"
                style={{ fontSize: "12px" }}
              >
                Joined : {moment(userData?.created_at).format("MMM Do YY")}
              </p>
            </>
          )}
        </div>
        <div className="container">
          <h3 className="mx-1">Personal Information</h3>
          <hr />
          {loading &&
            Array.from({ length: 4 }).map(() => (
              <>
                <Skeleton
                  sx={{
                    height: 20,
                    width: { lg: "50%", md: "50%", sm: "90%", xs: "100%" },
                    borderRadius: "6px",
                    marginTop: "1.5rem",
                  }}
                  animation="wave"
                  variant="rectangular"
                />
              </>
            ))}
          {loading == false && (
            <>
              <div className=" profile-design">
                <div className="row">
                  <div className="col-4">
                    <h6>Your Name</h6>
                  </div>
                  <div className="col-8">
                    <p>{userData?.username}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h6>Email</h6>
                  </div>
                  <div className="col-8">
                    <p
                      style={{
                        maxWidth: "100%",
                        overflowX: "scroll",
                        scrollbarWidth: "none",
                      }}
                    >
                      {userData?.email}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h6>Phone no</h6>
                  </div>
                  <div className="col-8">
                    <p>{userData?.phone_no}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">
                    <h6>Address</h6>
                  </div>
                  <div className="col-8">
                    <p
                      style={{
                        maxWidth: "100%",
                        overflowX: "scroll",
                        scrollbarWidth: "none",
                      }}
                    >
                      {userData?.address}
                    </p>
                  </div>
                </div>
              </div>
              <p
                className="text-warning"
                style={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={handleOpen}
              >
                Delete Account?
              </p>
            </>
          )}
        </div>
      </div>
      <div className="text-center">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            component: BlurBackdrop,
          }}
        >
          <div className="text-center">
            <Box sx={style}>
              <div className="text-end">
                <RxCross2
                  className="text-white h3"
                  onClick={handleCloseModel}
                  style={{ cursor: "pointer" }}
                />
              </div>

              {showOtpInput ? (
                <>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    className="text-white"
                    sx={{ mt: 2 }}
                  >
                    Check Your Email
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className="text-secondary"
                    sx={{ mt: 2, mb: 3 }}
                  >
                    Enter the code we just send to your email at :
                    aa****@gmail.com
                  </Typography>
                  <OTPInput />
                </>
              ) : (
                <>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    className="text-white"
                    sx={{ mt: 2 }}
                  >
                    Delete Account
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    className="text-secondary mb-2"
                    sx={{ mt: 1 }}
                  >
                    To continue, enter your email
                  </Typography>
                  <TextField
                    fullWidth
                    placeholder="Enter your email"
                    size="small"
                    onChange={(e) => setEmail(e.target.value)}
                    InputLabelProps={{
                      style: { color: "silver" },
                    }}
                    sx={{
                      input: { color: "white" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "silver",
                        },
                        "&:hover fieldset": {
                          borderColor: "silver",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "silver", // Border color on focus
                        },
                      },
                    }}
                  />

                  <button
                    className="btn btn-warning mt-4 fw-bold"
                    onClick={handleProceed}
                  >
                    Proceed
                  </button>
                </>
              )}
            </Box>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default Profile;
