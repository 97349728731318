import React, { useEffect, useState } from "react";
import logo from "../../assets/images/Goldlogo.png";
import { useNavigate } from "react-router-dom";
import { FcGoogle } from "react-icons/fc";
import { signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { auth } from "../../firebaseConfig";
import { LoginWithGoogleApi } from "../../DAL/Auth/auth";
import { useSnackbar } from "notistack";

const PreLogin = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [address, setAddress] = useState(null);
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      const formData = new FormData();
      formData.append("username", user?.displayName || "");
      formData.append("email", user?.email || "");
      formData.append("image", user?.photoURL || "");

      const resp = await LoginWithGoogleApi(formData);
      if (resp?.status) {
        localStorage.setItem("web_token", resp?.token);
        localStorage.setItem("user_data", JSON.stringify(resp?.user));
        enqueueSnackbar(resp.message, { variant: "success" });
        navigate("/");
      } else {
        enqueueSnackbar(resp?.message || "Login failed", { variant: "error" });
      }
    } catch (error) {
      console.error("Google Login Error:", error);
      enqueueSnackbar("Google Login Error", { variant: "error" });
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((pos) => {
      const { latitude, longitude } = pos.coords;
      const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`;
      fetch(url)
        .then((res) => res.json())
        .then((data) => setAddress(data.address));

    });
    document.title = 'Pre Login';
  }, []);

  return (
    <>
      <div style={{ paddingTop: "50px", height: "100vh" }}>
        <div className="text-center text-white">
          <img src={logo} alt="" width={130} height={100} />
          <p className="text-center mt-2">
            Your Location:{" "}
            <span className="text-warning">{address?.country || "Unknown"} </span>
          </p>
          <div className=" mt-5 p-5 pre-Login">
            <h4>Sign In</h4>

            <div className="text-center">
              <button
                className="btn btn-lg btn-light px-2 mt-2"
                onClick={handleGoogleLogin}
              >
                <FcGoogle className=" mx-1 mb-1" />
                Continue with Google
              </button>
              <br />
              <div
                className="text-center"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <hr style={{ border: "1px solid white", width: "30%" }} />
                <span className="mx-2 mt-1">or</span>
                <hr style={{ border: "1px solid white", width: "30%" }} />
              </div>
              <button
                className="btn btn-lg btn-warning "
                onClick={() => navigate("/login")}
              >
                Login with Password
              </button>
              <br />
              <div className="text-center mt-3">
                <p className="text-center mt-2">
                  Don't have any Account?{" "}
                  <span
                    className="text-warning"
                    onClick={() => navigate("/sign-up")}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                  >
                    Sign Up
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreLogin;
