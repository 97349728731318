import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { borderRadius, height } from "@mui/system";
import {
  DeletedAllItems,
  DeletedItem,
  ReadNotification,
  ShowNotification,
} from "../../DAL/Notification/Notification";
import { picBaseUrl } from "../../config/config";
import moment from "moment/moment";
import { useSnackbar } from "notistack";
import { useScroll } from "../../hooks/AppContext";
import { Card, CardContent, IconButton, Skeleton } from "@mui/material";
import BackspaceIcon from "@mui/icons-material/Backspace";
import ClearIcon from "@mui/icons-material/Clear";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "70%", md: "80%", sm: "92%", xs: "92%" },
  bgcolor: "#333333",
  borderRadius: "10px",
  // boxShadow: 24,
  height: "80vh",
  overflowY: "scroll",
  scrollbarWidth: "none",
};

export default function NotificationModel({ open, setOpen }) {
  const handleClose = () => setOpen(false);
  const [notifications, setNotifications] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [clickedCardId, setClickedCardId] = useState(false);

  const GetNotification = async () => {
    setLoading(true);
    const resp = await ShowNotification();
    if (resp?.status == true) {
      setNotifications(resp?.data);
      setLoading(false);
    }
  };

  const handleDelete = async (id) => {
    const resp = await DeletedItem(id);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetNotification();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleAllDelete = async () => {
    const resp = await DeletedAllItems();
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetNotification();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleNotifications = async (e, id) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("_method", "put");
    const resp = await ReadNotification(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      setClickedCardId(true);
      GetNotification();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    GetNotification();
  }, []);

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            {loading === true &&
              Array.from({ length: 4 }).map(() => (
                <>
                  <Skeleton
                    sx={{
                      height: 100,
                      width: "100%",
                      borderRadius: "10px",
                      marginTop: "10px",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                </>
              ))}

            {loading == false && (
              <Box
                sx={{
                  // backgroundColor: "#444",
                  color: "#fff",
                  width: "100%",
                  minHeight: "77vh",
                  height: "auto",
                  borderRadius: "10px",
                }}
              >
                <div
                  className="d-flex align-items-center justify-content-between "
                  style={{
                    position: "sticky",
                    top: "0px",
                    left: "0",
                    width: "100%",
                    padding: "10px",
                    borderRadius: "10px 10px 0 0",
                    zIndex: 10,
                    backgroundColor: "#232323",
                  }}
                >
                  <Typography variant="h5" className="text-warning">
                    Notifications
                  </Typography>
                  <IconButton>
                    <ClearIcon
                      sx={{ color: "white" }}
                      onClick={() => handleClose()}
                    />
                  </IconButton>
                </div>
                <div className="p-3">
                  {notifications?.length > 0 && (
                    <div className="text-end">
                      <Typography
                        variant="p"
                        align="right"
                        sx={{ mb: 2, cursor: "pointer" }}
                        onClick={() => handleAllDelete()}
                      >
                        Clear All
                      </Typography>
                    </div>
                  )}
                  {notifications?.length == 0 && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60vh",
                      }}
                    >
                      <h3 className="text-white">No Notification Available</h3>
                    </div>
                  )}
                  {notifications?.map((notification) => (
                    <>
                      <div className="row align-items-center ">
                        {/* <div className="col-1"></div> */}
                        <div className="col-10">
                          <Card
                            key={notification.id}
                            onClick={(e) =>
                              handleNotifications(e, notification.id)
                            }
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              mb: 2,
                              backgroundColor: "black",
                              color: "#fff",
                              borderRadius: "12px",
                              height: "auto",
                              padding: "1px",
                              boxShadow:
                                notification?.is_read == 1
                                  ? "none"
                                  : "-5px 0px 0px 0px #ffbf00",
                            }}
                          >
                            <Box
                              component="img"
                              src={
                                picBaseUrl + notification?.product?.thumbnail
                              }
                              alt=""
                              sx={{
                                width: { lg: 60, mg: 60, sm: 80, xs: 60 },
                                height: { lg: 60, mg: 60, sm: 80, xs: 60 },
                                borderRadius: "20%",
                                margin: "10px",
                              }}
                            />
                            <CardContent
                              sx={{
                                flexGrow: 1,
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div>
                                <Typography
                                  variant="body3"
                                  fontWeight="bold"
                                  sx={{ fontSize: "12px" }}
                                >
                                  {JSON.parse(notification?.message)?.title}
                                </Typography>

                                <br />
                                <Typography
                                  variant="body5"
                                  sx={{
                                    color: "lightgray",
                                    mb: 1,
                                    fontSize: "10px",
                                  }}
                                >
                                  {JSON.parse(notification?.message)?.body
                                    .length > 70
                                    ? JSON.parse(
                                        notification?.message
                                      )?.body.substring(0, 70) + "..."
                                    : JSON.parse(notification?.message)?.body}
                                </Typography>
                              </div>
                              <Typography
                                variant="body5"
                                align="right"
                                sx={{
                                  color: "grey",
                                  fontWeight: 600,
                                  fontSize: "10px",
                                }}
                              >
                                {moment(notification?.created_at).format(
                                  "YYYY-MM-DD"
                                )}
                              </Typography>
                            </CardContent>
                          </Card>
                        </div>
                        <div className="col-1">
                          <IconButton
                            onClick={() => handleDelete(notification.id)}
                            sx={{ color: "silver" }}
                          >
                            <BackspaceIcon />
                          </IconButton>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </Box>
            )}
          </div>
        </Box>
      </Modal>
    </div>
  );
}
