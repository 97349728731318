import React, {
  createContext,
  useRef,
  useContext,
  useState,
  useEffect,
} from "react";
import { CartQuantity } from "../DAL/Cart/Cart";
import { GetNotification } from "../DAL/Notification/Notification";
import { useNavigate } from "react-router-dom";

const Context = createContext();

export const AppContext = ({ children }) => {
  const sectionRefs = useRef({});
  const navigate = useNavigate();
  const sectionsRefs = useRef({});
  const [quantity, setQuantity] = useState(0);
  const [notificationQuantity, setNotificationQuantity] = useState(0);
  const [token, setToken] = useState(null);

  const GetCartQuantity = async () => {
    const resp = await CartQuantity();
    if (resp?.status == true) {
      setQuantity(resp?.data);
    }
  };
  const GetBellNotification = async () => {
    const resp = await GetNotification();
    if (resp?.status == true) {
      setNotificationQuantity(resp?.data);
    }
  };
  const NoToken = () => {
    if (!localStorage.getItem("web_token")) {
      navigate("/pre-login");
    }
  };
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    GetCartQuantity();
    GetBellNotification();
  }, []);

  const bundle = {
    sectionRefs,
    sectionsRefs,
    GetCartQuantity,
    quantity,
    handleScrollToTop,
    notificationQuantity,
    GetBellNotification,
    NoToken,
  };

  return <Context.Provider value={bundle}>{children}</Context.Provider>;
};

export const useScroll = () => useContext(Context);
