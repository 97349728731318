import React from "react";
import { FaArrowDownWideShort, FaArrowUpWideShort } from "react-icons/fa6";
import { PiSoccerBallLight } from "react-icons/pi";
import { RiArrowGoBackLine, RiExchangeDollarLine } from "react-icons/ri";
import ReplyIcon from "@mui/icons-material/Reply";
import { SiOpenmined } from "react-icons/si";

const GoldRates = () => {
  return (
    <>
      <div className="text-center text-white mainDiv">
        <h2>Rates</h2>
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-4 p-4 " style={{ backgroundColor: "black" }}>
              <h5 className="text-start">Select Currency</h5>
              <div
                className="container"
                style={{
                  backgroundColor: "#2C2C2C",
                  height: "20vh",
                  paddingTop: "5vh",
                }}
              >
                <RiExchangeDollarLine className="h1" />
                <h4>USD- United Kingdom</h4>
              </div>
              <h5 className="mt-3 text-start">Select Metal</h5>
              <div
                className="container"
                style={{
                  backgroundColor: "#2C2C2C",
                  height: "20vh",
                  paddingTop: "5vh",
                }}
              >
                <PiSoccerBallLight className="h1" />
                <h4>XPT PLATIUNM</h4>
              </div>
              <button className="btn  px-5 btn-warning mt-3 fw-bold">
                Submit
              </button>
            </div>
            <div className="col-lg-8 ">
              <div
                className="p-2 rate-table"
                style={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                  backgroundColor: "black",
                  justifyContent: "space-around",
                }}
              >
                <div>
                  <p className="text-secondary">Price</p>
                  <p>923.468</p>
                </div>
                <div style={{ border: "1px solid grey", height: "50px" }}></div>
                <div>
                  <p className="text-secondary">Ask</p>
                  <p>929.415</p>
                </div>
                <div style={{ border: "1px solid grey", height: "50px" }}></div>
                <div>
                  <p className="text-secondary">Bid</p>
                  <p>927.52</p>
                </div>
                <div style={{ border: "1px solid grey", height: "50px" }}></div>
                <div>
                  <p className="text-secondary">Change</p>
                  <p>-4.29</p>
                </div>
                <div style={{ border: "1px solid grey", height: "50px" }}></div>
                <div>
                  <p className="text-secondary">Percent</p>
                  <p>-0.46</p>
                </div>
              </div>{" "}
              <br />
              <div style={{ backgroundColor: "black" }}>
                <div className="p-5 text-start">
                  <h3>Price</h3>
                  <div className="container">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div className="d-flex gap-3">
                        <ReplyIcon className="h3 text-white" />
                        <h4>Previous Close Price</h4>
                      </div>
                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#1A5820",
                          color: "#00FF38",
                          border: "none",
                        }}
                      >
                        932.758
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div className="d-flex gap-3 text-start">
                        <SiOpenmined className="h3" />
                        <h4>Open Price</h4>
                      </div>
                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#1A5820",
                          color: "#00FF38",
                          border: "none",
                        }}
                      >
                        932.758
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div className="d-flex gap-3">
                        <FaArrowUpWideShort className="h3" />
                        <h4>High Price</h4>
                      </div>
                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#1A5820",
                          color: "#00FF38",
                          border: "none",
                        }}
                      >
                        932.758
                      </button>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "20px",
                      }}
                    >
                      <div className="d-flex gap-3">
                        <FaArrowDownWideShort className="h3" />
                        <h4>Low Price</h4>
                      </div>
                      <button
                        style={{
                          padding: "10px 20px",
                          backgroundColor: "#1A5820",
                          color: "#00FF38",
                          border: "none",
                        }}
                      >
                        932.758
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        <br />
        <div className="rate p-5" style={{ width: "83%", margin: "auto" }}>
          <h4 className="text-start">Gram Prices:</h4>
          <table
            className="gram-prices-table rate-table"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <thead>
              <tr style={{ display: "flex", justifyContent: "space-around" }}>
                <th>Units</th>
                <th>Price</th>
                <th>Units</th>
                <th>Price</th>
                <th>Units</th>
                <th>Price</th>
              </tr>
            </thead>
            <hr />
            <tbody>
              <tr style={{ display: "flex", justifyContent: "space-around" }}>
                <td>24k</td>
                <td>$ 29.8509</td>
                <td>20K</td>
                <td>24.8758</td>
                <td>20K</td>
                <td>24.8758</td>
              </tr>
              <tr style={{ display: "flex", justifyContent: "space-around" }}>
                <td>22K</td>
                <td>$ 27.3634</td>
                <td>18K</td>
                <td>22.3882</td>
                <td>18K</td>
                <td>22.3882</td>
              </tr>
              <tr style={{ display: "flex", justifyContent: "space-around" }}>
                <td>21K</td>
                <td>$ 26.1196</td>
                <td>16K</td>
                <td>22.3882</td>
                <td>16K</td>
                <td>22.3882</td>
              </tr>
              <tr style={{ display: "flex", justifyContent: "space-around" }}>
                <td>21K</td>
                <td>$26.1196</td>
                <td>14K</td>
                <td>22.3882</td>
                <td>14K</td>
                <td>22.3882</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default GoldRates;
