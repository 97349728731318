import { Button, Rating, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ProductReviewsAdd } from "../../DAL/Products/Products";
import { enqueueSnackbar } from "notistack";

export default function AddReview({ setData }) {
  const { id } = useParams();
  const [rating, setRating] = useState();
  const [comment, setComment] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!rating) {
      enqueueSnackbar("Rating field is required", { variant: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("rating", rating);
    formData.append("product_id", id);
    formData.append("comment", comment);

    const resp = await ProductReviewsAdd(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      setData(0);
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };
  return (
    <>
      <div className="text-center mt-3">
        <Typography variant="subtitle1" className="text-white">
          How would you rate your Experience?
        </Typography>
        <Rating
          name="simple-controlled"
          size="large"
          value={rating}
          sx={{
            "& .MuiRating-icon": {
              color: "gold", // Gold fill color for the stars
            },
          }}
          onChange={(event, newValue) => {
            setRating(newValue);
          }}
        />
        <Typography variant="subtitle1" className="text-white">
          Please share your opinion
          <br /> about the product
        </Typography>
      </div>
      <div>
        <TextField
          fullWidth
          multiline
          rows={5}
          name="comment"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder="Write Review"
          InputProps={{
            style: { color: "white" }, // Input text color
          }}
          InputLabelProps={{
            style: { color: "white" }, // Label text color
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "silver", // Border color when not focused
              },
              "&:hover fieldset": {
                borderColor: "silver", // Border color when hovered
              },
              "&.Mui-focused fieldset": {
                borderColor: "silver", // Border color when focused
              },
            },
          }}
        />
      </div>
      <div className="text-end">
        <Button
          className="addtoCart mt-2 text-dark"
          sx={{ textTransform: "none" }}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </div>
    </>
  );
}
