import { Box, Modal, styled, Typography } from "@mui/material";
import React from "react";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#181818",
  boxShadow:
    "0px 4px 10px #534005, 4px 0px 10px #534005, -4px 0px 10px #534005, 0px -4px 10px #534005",
  p: 4,
  animation: "slideDown 0.5s ease",
  borderRadius: "12px",
};

const BlurBackdrop = styled("div")({
  backdropFilter: "blur(8px)",
});

const globalStyles = `
  @keyframes slideDown {
    from {
      transform: translate(-50%, -60%);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
`;

export default function SuccessModel({ open, setOpen }) {
  const navigate = useNavigate();
  const handleClose = () => {
    navigate("/login");
    setOpen(false);
  };
  return (
    <>
      <div className="text-center">
        <style>{globalStyles}</style>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          BackdropProps={{
            component: BlurBackdrop,
          }}
        >
          <div className="text-center">
            <Box sx={style}>
              <div className="text-end">
                <RxCross2
                  className="text-white h3 pe-auto"
                  onClick={handleClose}
                  role="button"
                />
              </div>
              <Typography id="modal-modal-title" variant="h1" component="h1">
                <FaCheckCircle style={{ color: "#D5AD3B" }} />
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h6"
                className="text-white"
                sx={{ mt: 2 }}
              >
                You’re All Set!
              </Typography>
              <Typography
                id="modal-modal-description"
                className="text-secondary"
                sx={{ mt: 2 }}
              >
                At Sassy Style, we believe that fashion is an expression of
                individuality, confidence, and creativity.
              </Typography>
              <Typography
                id="modal-modal-title"
                variant="h3"
                component="h3"
                className="text-white"
                sx={{ mt: 2 }}
              >
              </Typography>
            </Box>
          </div>
        </Modal>
      </div>
    </>
  );
}
