import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Pagination,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TfiShoppingCart } from "react-icons/tfi";
import { GiRoundStar } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { FavouriteProducts } from "../../DAL/Products/Products";
import { picBaseUrl } from "../../config/config";

export default function Favorites() {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const GetProducts = async () => {
    setLoading(true);
    const resp = await FavouriteProducts();
    if (resp?.status == true) {
      setData(resp?.data);
      setLoading(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    GetProducts();
  }, []);
  return (
    <>
      <div className="mainDiv">
        <div
          className="text-start mt-4"
          style={{ width: "94%", margin: "auto" }}
        >
          <Typography variant="h5" className="text-white">
            Favourite Products
          </Typography>
        </div>
        <div style={{ width: "96%", margin: "auto" }}>
          <div className="row  m-auto">
            {loading == true &&
              Array.from({ length: 4 }).map((val) => (
                <>
                  <div className="col-lg-3 col-mg-6 col-sm-12 mt-4">
                    <Skeleton
                      sx={{ height: 370, width: "100%", borderRadius: "20px" }}
                      animation="wave"
                      variant="rectangular"
                    />
                  </div>
                </>
              ))}
            {loading == false && data?.length == 0 && (
              <h4 className="text-center text-white mt-5 mb-5">
                No Favourites Yet
              </h4>
            )}
            {loading == false &&
              data?.map((val) => (
                <>
                  <div className="col-lg-3 mt-4">
                    <Card
                      className="card favouriteCards h-100"
                      sx={{
                        padding: "10px 10px 0px 10px",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        navigate(`/product-detail/${val?.product_id}`)
                      }
                    >
                      {val?.product !== null && val?.product?.discount !== 0 ? (
                        <button className="discountBtn">
                          {val?.product?.discount + "%"}
                        </button>
                      ) : (
                        ""
                      )}

                      <CardMedia
                        sx={{
                          height: 170,
                          borderRadius: "10px",
                        }}
                        image={picBaseUrl + val?.product?.thumbnail}
                        alt=""
                      />
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          flexGrow: 1, // This will make the content fill the vertical space
                        }}
                      >
                        <Typography
                          gutterBottom
                          variant="h6"
                          component="div"
                          className="text-white"
                        >
                          {val?.product?.name.length > 20
                            ? val?.product?.name.substring(0, 20) + "..."
                            : val?.product?.name}
                        </Typography>

                        <Typography
                          gutterBottom
                          variant="p"
                          component="div"
                          className="text-white"
                        >
                          Rs. {val?.product?.price}
                        </Typography>
                        <Box sx={{ flexGrow: 1 }} />
                        <div className="d-flex justify-content-between align-items-center mt-3">
                          <div className="d-flex ">
                            <GiRoundStar
                              style={{ fontSize: "27px" }}
                              color="gold"
                            />
                            <span
                              className="mx-2  text-secondary"
                              style={{ fontSize: "19px" }}
                            >
                              {val?.product?.rating}{" "}
                              <span>({val?.product?.reviews_count})</span>
                            </span>
                          </div>
                          <TfiShoppingCart
                            style={{ fontSize: "31px", marginTop: "10px" }}
                            color="gold"
                          />
                        </div>
                      </CardContent>
                    </Card>
                  </div>
                </>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
