import { FormHelperText } from "@mui/material";
import React, { useEffect } from "react";

const Shipping = () => {
  useEffect(() => {
    document.title = "Shipping Policy || Pakistan&#039;s 1st Online Gold Jewellery Shopping Bazaar";
  }, []);
  return (
    <div className="container policy mainDiv  text-white">
      <div className="text-center">
        <p className="text-center">Last updated: August 26, 2024</p>
        <h1 className="text-warning">Shipping Policy</h1>
      </div>
      <div className="shipping">
        <p>
          At <b>Gold Bazaar</b>, we strive to ensure that your order is
          delivered to you safely and on time. Please read our shipping policy
          carefully to understand the terms and conditions of our shipping
          services.
        </p>
        <h2>Shipping Destinations</h2>
        <ul>
          <li>
            <span className="d-flex flex-wrap gap-1 align-items-center">
              <strong className="fw-bold"> Domestic Shipping: </strong>
              <FormHelperText className="text-white">
                We offer shipping to all locations within Pakistan.
              </FormHelperText>
            </span>
          </li>
          <li>
            <span className="d-flex flex-wrap gap-1 align-items-center">
              <strong className="fw-bold"> International Shipping: </strong>
              <FormHelperText className="text-white">
                We currently do not offer international shipping.
              </FormHelperText>
            </span>
          </li>
        </ul>
        <h2>Processing Time & Delivery</h2>
        <ul>
          <li>
            <FormHelperText className="text-white">
              Once your order has been placed, you will receive an order
              confirmation email or call with details of your purchase.
            </FormHelperText>
          </li>
          <li>
            {" "}
            <FormHelperText className="text-white">
              All orders are processed within 7 business days.
            </FormHelperText>
          </li>
          <li>
            <FormHelperText className="text-white">
              Currently, we are not delivering products to your doorstep.
              Customer will be informed through the mobile app notification,
              email and phone call about the nearest pick up destination for his
              order.
            </FormHelperText>
          </li>
        </ul>
        <h2>Shipping Methods</h2>
        <ul>
          <li>
            {" "}
            <FormHelperText className="text-white">
              The customer will pick up the order from the seller's shop.
            </FormHelperText>{" "}
          </li>
          <li>
            <FormHelperText className="text-white">
              We currently do not work with the trusted carriers to deliver your
              orders.
            </FormHelperText>
          </li>
        </ul>
        <h2>Delayed Shipments</h2>
        <ul>
          <li className="d-flex flex-wrap align-items-center">
            <strong className="fw-bold">Delayed Shipments:</strong>{" "}
            <FormHelperText className="text-white">
              While we strive to meet our delivery estimates, delays may occur
              due to unforeseen circumstances. We will do our best to keep you
              informed of any delays.
            </FormHelperText>{" "}
          </li>
        </ul>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Shipping Policy, You can contact
          us at:
        </p>
        <ul>
          <li>
            <b>Phone:</b> <span>+923004425678</span>
          </li>
          <li>
            <b>Email:</b> <span>support@goldbazaar.com</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Shipping;
