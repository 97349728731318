import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { borderRadius } from "@mui/system";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "25%", md: "50%", sm: "75%", xs: "90%" },
  bgcolor: "#404040",
  borderRadius: "10px",
  border: "none",
  p: 2,
};

export default function ConfirmationModel({
  open,
  setOpen,
  title,
  heading,
  handleDeleteData,
}) {
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            className="text-center text-white"
            sx={{ fontSize: "15px" }}
          >
            {heading ? heading : "Delete"}
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            sx={{ color: "#A6A6A6", textAlign: "center", fontSize: "14px" }}
          >
            {title ? title : "Are you sure you want to delete?"}
          </Typography>
          <div className="d-flex gap-3 justify-content-center mt-3">
            <button className="common-button" onClick={handleDeleteData}>
              Delete
            </button>
            <button className="cancel-button" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
