import { invokeApi } from "../../utils";

export const Categories = async () => {
  const requestObj = {
    path: `/api/web/user/category/list`,
    method: "GET",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const CategoryProduct = async (id, page) => {
  const requestObj = {
    path: `/api/user/category/product/${id}?page=${page}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const GetAllProductCategories = async () => {
  const requestObj = {
    path: `/api/user/category/list`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const SellerCategoryProducts = async (id, page) => {
  const requestObj = {
    path: `/api/user/category/product/${id}?page=${page}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};
