import React, { useEffect, useState } from "react";
import logo from "../../assets/images/Goldlogo.png";
import { Avatar, Box, Rating, Skeleton, Typography } from "@mui/material";
import ConfirmationModel from "../../components/ConfirmationModel";
import {
  ProductReviews,
  ProductReviewsDelete,
} from "../../DAL/Products/Products";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { picBaseUrl } from "../../config/config";

export default function ReviewSection({ setData }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const [reviewData, setReviewData] = useState([]);
  const [reviewId, setReviewId] = useState();
  const [loading, setLoading] = useState(false);

  const getReviews = async () => {
    setLoading(true);
    const resp = await ProductReviews(id);
    if (resp?.status == true) {
      setReviewData(resp?.data);
      setLoading(false);
    }
  };

  const handleOpenModel = (Id) => {
    setReviewId(Id);
    setOpen(true);
  };

  const handleDelete = async () => {
    setOpen(false);
    const resp = await ProductReviewsDelete(reviewId);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      getReviews();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    getReviews();
  }, [id]);
  return (
    <>
      <div className="p-3 mt-2 reviewsMain">
        {loading == true &&
          Array.from({ length: 2 }).map(() => (
            <>
              <Box sx={{ display: "flex", marginTop: "1rem" }}>
                <Skeleton
                  sx={{
                    height: 80,
                    width: 80,
                    borderRadius: "50%",
                  }}
                  animation="wave"
                  variant="circle"
                />
                <div>
                  <Skeleton
                    sx={{
                      height: 30,
                      width: 725,
                      borderRadius: "20px",
                      marginTop: "5px",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                  <Skeleton
                    sx={{
                      height: 30,
                      width: 725,
                      borderRadius: "20px",
                      marginTop: "5px",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                  <Skeleton
                    sx={{
                      height: 30,
                      width: 725,
                      borderRadius: "20px",
                      marginTop: "5px",
                    }}
                    animation="wave"
                    variant="rectangular"
                  />
                </div>
              </Box>
            </>
          ))}
        {loading == false && reviewData?.length == 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "30vh",
            }}
          >
            <h4 className="text-white">No Reviews Yet</h4>
          </div>
        )}

        {loading == false &&
          reviewData?.map((val) => (
            <>
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  marginTop: "1rem",
                  padding: "10px",
                }}
              >
                <Avatar
                  src={picBaseUrl + val?.user?.image}
                  sx={{ width: 80, height: 80 }}
                />
                <div>
                  <Typography variant="subtitle1" className="text-white">
                    {val?.user?.username}
                  </Typography>
                  <Rating
                    name="read-only"
                    value={val?.rating}
                    readOnly
                    size="small"
                  />
                  <Typography variant="subtitle2" className="text-white">
                    {val?.comment}
                  </Typography>
                </div>
              </Box>

              <div className="d-flex gap-1 justify-content-end text-white">
                {val?.is_review == true && (
                  <>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setData(2);
                        navigate(".", { state: val });
                      }}
                    >
                      Edit |
                    </p>
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() => handleOpenModel(val?.id)}
                    >
                      Delete
                    </p>
                  </>
                )}
              </div>
              {val?.is_review == true && <hr style={{ color: "white" }} />}
            </>
          ))}
      </div>

      <ConfirmationModel
        open={open}
        setOpen={setOpen}
        heading={"DELETE REVIEW"}
        title={"Are you sure you want to delete this review?"}
        handleDeleteData={handleDelete}
      />
    </>
  );
}
