import { invokeApi } from "../../utils";

export const SignUp = async (data) => {
  const requestObj = {
    path: `/api/user/register`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const LoginApi = async (data) => {
  const requestObj = {
    path: `/api/user/login`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const LoginWithGoogleApi = async (data) => {
  const requestObj = {
    path: `/api/user/gmail`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const LogoutApi = async () => {
  const requestObj = {
    path: `/api/user/logout`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const ForgetPasswordApi = async (data) => {
  const requestObj = {
    path: `/api/user/forget_password`,
    method: "POST",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const OtpVerifyApi = async (data) => {
  const requestObj = {
    path: `/api/user/password/otp/verify`,
    method: "POST",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const ResetPassword = async (data) => {
  const requestObj = {
    path: `/api/user/new/password/set`,
    method: "POST",
    headers: {
      // Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
