import React from "react";
import "./App.css";
import RoutesFile from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";
import { SnackbarProvider } from "notistack";
import { IconButton, Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { QueryClient, QueryClientProvider } from "react-query";
import { I18nextProvider  } from "react-i18next";
import i18n from "./components/i18n";

function App() {
  const queryClient = new QueryClient();
  const notiStackRef = React.createRef();
  const onClickDismiss = (key) => () => {
    notiStackRef.current.closeSnackbar(key);
  };
 

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          hideIconVariant
          ref={notiStackRef}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          TransitionComponent={Slide}
          maxSnack={3}
          autoHideDuration={2000}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)}>
              <CloseIcon htmlColor="white" />
            </IconButton>
          )}
        >
       
        <I18nextProvider i18n={i18n}>
          <RoutesFile />
          </I18nextProvider>
        </SnackbarProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
