import {
  Card,
  CardContent,
  CardMedia,
  Pagination,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";

export default function CategoriesListing() {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "dshjgfjkhsdkjhflkds");
  const [page, setPage] = useState(1);

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <>
      <div className="mainDiv">
        <div
          style={{
            width: "95%",
            margin: "3rem auto 0px auto",
          }}
        ></div>
        <div style={{ width: "93%", margin: "auto" }}>
          <div className="row">
            <Typography variant="h6" className="text-white">
              All Categories
            </Typography>
            {state?.map((val) => {
              return (
                <div className="col-lg-3 mt-5">
                  <Card className="card cardClassCategory h-100">
                    <CardMedia
                      sx={{ height: 175, width: "100%", cursor: "pointer" }}
                      image={picBaseUrl + val?.thumbnail}
                      title="green iguana"
                      onClick={() =>
                        navigate(`/category-products/${val?.id}`, {
                          state: val?.title,
                        })
                      }
                    />
                    <CardContent className="cardContent">
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        className="text-warning"
                      >
                        {val?.title}
                      </Typography>
                      <Typography variant="subtitle2">
                        {val?.description?.length > 50
                          ? `${val.description.substring(0, 50)}...`
                          : val?.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
