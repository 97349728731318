import { invokeApi } from "../../utils";

export const AllCategoryButtons = async () => {
  const requestObj = {
    path: `/api/category/list`,
    method: "GET",
    headers: {},
  };
  return invokeApi(requestObj);
};

export const AllBlogs = async (id, page) => {
  const requestObj = {
    path: `/api/blog/list/${id}?page=${page}`,
    method: "GET",
    headers: {},
  };
  return invokeApi(requestObj);
};

export const BlogDetail = async (id) => {
  const requestObj = {
    path: `/api/blog/detail/${id}`,
    method: "GET",
    headers: {},
  };
  return invokeApi(requestObj);
};
