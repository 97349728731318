import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import Typography from "@mui/material/Typography";
import one from "../../assets/images/1.webp";
import two from "../../assets/images/2.webp";
import three from "../../assets/images/3.png";
import test from "../../assets/video/test.mp4";
import { padding, textTransform } from "@mui/system";
import { Button, Grid } from "@mui/material";

const Becomeasupplier = () => {
  return (
    <>
      <div className="mainDiv">
        <h2 className="text-white text-center mt-4">
          3 Simple Steps to Become a Seller
        </h2>
        <div className="mt-5" style={{ backgroundColor: "black" }}>
          <Grid container spacing={2}>
            <Grid item lg={6} xs={12} sm={12} sx={{ textAlign: "center" }}>
              <img
                src={one}
                alt=""
                style={{
                  width: "50%",
                  height: "37vh",
                }}
                className="my-5 img-media"
              />
            </Grid>
            <Grid
              item
              lg={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginBottom: { sm: "1.5rem", xs: "1.5rem" },
              }}
            >
              <Typography
                variant="h4"
                className="text-warning"
                sx={{
                  fontSize: {
                    lg: "25px",
                    md: "20px",
                    sm: "23px",
                    xs: "23px",
                  },
                  marginLeft: { sm: "1rem", xs: "1rem" },
                }}
              >
                Step 1
              </Typography>
              <Typography
                variant="h6"
                className="text-white mt-3"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "14px", xs: "14px" },
                  marginLeft: { sm: "1rem", xs: "1rem" },
                }}
              >
                REGISTER AND LIST YOUR PRODUCTS
              </Typography>
              <ul className="mt-3 text-white">
                <li style={{ fontSize: "1rem" }}>
                  Fill the registration form with details about yourself and
                  your shop
                </li>
                <li style={{ fontSize: "1rem", marginTop: "10px" }}>
                  Log in into the dashboard with your credentials
                </li>
                <li style={{ fontSize: "1rem", marginTop: "10px" }}>
                  List your products by providing all relevant information
                </li>
                <li style={{ fontSize: "1rem", marginTop: "10px" }}>
                  Request for promotions of your products
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>
        <div className="mt-5">
          <Grid container spacing={2}>
            <Grid
              item
              lg={6}
              className="order-lg-1 order-2"
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: { lg: "center", md: "center", sm: "start" },
                alignItems: { lg: "center" },
              }}
            >
              <div>
                <Typography
                  variant="h4"
                  className="text-warning"
                  sx={{
                    fontSize: {
                      lg: "25px",
                      md: "20px",
                      sm: "23px",
                      xs: "23px",
                    },
                    marginLeft: { sm: "1rem", xs: "1rem" },
                  }}
                >
                  Step 2
                </Typography>
                <Typography
                  variant="h6"
                  className="text-white mt-3"
                  sx={{
                    fontSize: {
                      lg: "18px",
                      md: "15px",
                      sm: "14px",
                      xs: "14px",
                    },
                    marginLeft: { sm: "1rem", xs: "1rem" },
                  }}
                >
                  RECEIVE ORDERS AND SELL ACROSS PAKISTAN
                </Typography>
              </div>
              <ul className="mt-3 text-white">
                <li style={{ fontSize: "1rem" }}>
                  Start selling once products are listed
                </li>
                <li style={{ fontSize: "1rem", marginTop: "10px" }}>
                  Receive orders and manage them through your seller account
                </li>
              </ul>
            </Grid>
            <Grid
              item
              lg={6}
              className="order-lg-2 order-1"
              xs={12}
              sm={12}
              sx={{ textAlign: { sm: "center", xs: "center" } }}
            >
              <img
                src={two}
                alt=""
                style={{ width: "50%", height: "37vh" }}
                className="my-5 img-media"
              />
            </Grid>
          </Grid>
        </div>
        <div className="mt-5" style={{ backgroundColor: "black" }}>
          <Grid container spacing={2}>
            <Grid
              item
              lg={6}
              xs={12}
              sm={12}
              sx={{
                textAlign: {
                  lg: "center",
                  md: "center",
                  sm: "center",
                  xs: "center",
                },
              }}
            >
              <img
                src={three}
                alt=""
                style={{
                  width: "50%",
                  height: "37vh",
                }}
                className="my-5 img-media"
              />
            </Grid>
            <Grid
              item
              lg={6}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginBottom: { sm: "1.5rem", xs: "1.5rem" },
              }}
            >
              <Typography
                variant="h4"
                className="text-warning"
                sx={{
                  fontSize: {
                    lg: "25px",
                    md: "20px",
                    sm: "23px",
                    xs: "23px",
                  },
                  marginLeft: { sm: ".6rem", xs: ".6rem" },
                }}
              >
                Step 3
              </Typography>
              <Typography
                variant="h6"
                className="text-white mt-3"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "14px", xs: "14px" },
                  marginLeft: { sm: ".6rem", xs: ".6rem" },
                }}
              >
                GET PAYMENTS AND GROW YOUR BUSINESS
              </Typography>
              <ul className="mt-3 text-white px-4">
                <li style={{ fontSize: "1rem" }}>
                  Receive payments directly in your account
                </li>
                <li style={{ fontSize: "1rem", marginTop: "10px" }}>
                  Expand your business
                </li>
              </ul>
            </Grid>
          </Grid>
        </div>

        {/* <Timeline position="alternate" className=" text-white">
          <h1 className="text-center text-warning ">
            3 Simple Steps to Become a Seller
          </h1>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0", display: "flex", justifyContent: "center" }}
              align="center"
              variant="body2"
              color="text.secondary"
            >
              <img
                src={one}
                alt=""
                style={{ width: "80%" }}
                className="my-5 img-media"
              />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className="bg-black">
                <h3 className="mx-3 pt-2">1</h3>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent
              sx={{
                py: "10%",
                m: "auto 0",
                display: "block",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="h4"
                className="text-warning text-media fw-bold"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "15px", xs: "15px" },
                }}
              >
                Step 1
              </Typography>
              <Typography
                variant="h6"
                className="text-media mt-3"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "8px", xs: "10px" },
                }}
              >
                REGISTER AND LIST YOUR PRODUCTS
              </Typography>
              <ul
                className="para-media mt-3"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "8px", xs: "10px" },
                }}
              >
                <li>
                  Fill the registration form with details about yourself and
                  your shop
                </li>
                <li>Log in into the dashboard with your credentials</li>
                <li>
                  List your products by providing all relevant information
                </li>
                <li>Request for promotions of your products</li>
              </ul>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0", display: "flex", justifyContent: "center" }}
              variant="body2"
              color="text.secondary"
            >
              <img
                src={two}
                alt=""
                style={{ width: "80%" }}
                className="my-5 img-media"
              />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className="bg-black">
                <h3 className="mx-3 pt-2">2</h3>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "10%" }}>
              <Typography
                variant="h4"
                className="text-warning text-start text-media fw-bold"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "15px", xs: "15px" },
                }}
              >
                Step 2
              </Typography>
              <Typography
                variant="h6"
                className="text-start text-media mt-3"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "8px", xs: "10px" },
                }}
              >
                RECEIVE ORDERS AND SELL ACROSS PAKISTAN
              </Typography>
              <ul
                className="text-start para-media mt-3"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "8px", xs: "10px" },
                }}
              >
                <li> Start selling once products are listed</li>
                <li>
                  {" "}
                  Receive orders and manage them through your seller account
                </li>
              </ul>
            </TimelineContent>
          </TimelineItem>
          <TimelineItem>
            <TimelineOppositeContent
              sx={{ m: "auto 0", display: "flex", justifyContent: "center" }}
              align="center"
              variant="body2"
              color="text.secondary"
            >
              <img
                src={three}
                alt=""
                style={{ width: "80%" }}
                className="my-5 img-media"
              />
            </TimelineOppositeContent>
            <TimelineSeparator>
              <TimelineDot className="bg-black">
                <h3 className="mx-3 pt-2">3</h3>
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: "10%" }}>
              <Typography
                variant="h4"
                className="text-warning text-media fw-bold"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "15px", xs: "15px" },
                }}
              >
                Step 3
              </Typography>
              <Typography
                variant="h6"
                className="text-media mt-3"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "8px", xs: "10px" },
                }}
              >
                GET PAYMENTS AND GROW YOUR BUSINESS
              </Typography>
              <ul
                className="para-media mt-3"
                sx={{
                  fontSize: { lg: "18px", md: "15px", sm: "8px", xs: "10px" },
                }}
              >
                <li> Receive payments directly in your account</li>
                <li>Expand your business</li>
              </ul>
            </TimelineContent>
          </TimelineItem>
        </Timeline> */}
      </div>

      {/*------------------------------------------ Video Section ---------------------------*/}
      <div className="text-center container text-white mt-5">
        <video
          src={test}
          muted
          autoPlay
          controls
          loop
          className="video"
        ></video>
      </div>
      <div className="seller-bg-img text-center mt-5">
        <Button
          className="addtoCart"
          variant="contained"
          sx={{
            textTransform: "none",
            marginTop: "5%",
            backgroundColor: "#ffbf00",
          }}
        >
          {" "}
          <a
            href="https://goldbazar.shop/seller/register"
            className="pt-1 text-black mx-3 fw-bold text-decoration-none btn btn-warning"
          >
            Start Selling
          </a>{" "}
        </Button>
        <p
          className="text-white mt-3 text-center"
          style={{ paddingBottom: "5%" }}
        >
          Already a Seller?{" "}
          <a
            href="https://goldbazar.shop/seller/login"
            className="text-warning text-decoration-none"
          >
            Login here
          </a>
        </p>
      </div>
    </>
  );
};
export default Becomeasupplier;
