import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AllBlogs, AllCategoryButtons } from "../../DAL/Blog/Blog";
import DataNotFound from "../../assets/images/DataNotFound.png";
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Pagination,
  Skeleton,
} from "@mui/material";
import { picBaseUrl } from "../../config/config";
import { useQuery } from "react-query";
import moment from "moment";

const Blog = () => {
  const navigate = useNavigate();
  const [active, SetActive] = useState("all");
  const [btnData, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [blogs, setBlogs] = useState(
    JSON.parse(localStorage.getItem("blogs_data")) || []
  );
  const [btnLoading, setBtnLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const handlePage = (id) => {
    navigate(`/blog-detail/${id}`);
  };

  const GetButtons = async () => {
    setBtnLoading(true);
    const resp = await AllCategoryButtons();
    if (resp.status === true) {
      localStorage.setItem("blogs_btn", JSON.stringify(resp?.data));
      setData(resp?.data);

      setBtnLoading(false);
    }
  };

  const GetAllBlogs = async (id) => {
    setIsLoading(true);
    const result = await AllBlogs(
      id === "all" || id === undefined ? "" : id,
      page
    );
    if (result.status === true) {
      localStorage.setItem("blogs_data", JSON.stringify(result?.data));
      setBlogs(result?.data?.data);
      setTotalPages(Math.ceil(result?.data?.total / result?.data?.per_page));
      setIsLoading(false);
    }
  };

  const handleFilterBlogs = async (id) => {
    SetActive(id);
    GetAllBlogs(id);
  };
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    GetAllBlogs();
  }, [page]);

  useEffect(() => {
    const GetBlogsButton = localStorage.getItem("blogs_btn");

    if (GetBlogsButton) {
      setData(JSON.parse(GetBlogsButton));
      GetButtons();
    } else {
      GetButtons();
    }
  }, []);
  return (
    <div>
      <div className="container mb-5 mainDiv">
        <h2 className="text-center text-white">BLOGS</h2>

        <div
          className="mt-5 mb-4"
          style={{
            display: "flex",
            whiteSpace: "nowrap",
            gap: "25px",
            margin: "auto",
            overflowX: "scroll",
            scrollbarWidth: "none",
            backgroundColor: "#545454",
            padding: "5px",
            borderRadius: "70px",
          }}
        >
          {btnData?.length > 0 && (
            <button
              className={
                active === "all" ? "btn after-active1" : "btn btn-color"
              }
              onClick={() => handleFilterBlogs("all")}
            >
              All
            </button>
          )}

          {/* btn skeleton loader starts */}

          {btnLoading === true &&
            Array.from({ length: 10 }).map(() => (
              <>
                <Skeleton
                  sx={{ height: 40, width: 100, borderRadius: "20px" }}
                  animation="wave"
                  variant="rectangular"
                />
              </>
            ))}

          {/* btn data  */}
          {!btnLoading &&
            btnData?.map((val, i) => {
              return (
                <>
                  <button
                    className={
                      val?.id === active ? "btn after-active1" : "btn btn-color"
                    }
                    onClick={() => handleFilterBlogs(val?.id)}
                  >
                    {val?.title}
                  </button>
                </>
              );
            })}

          {btnData.length === 0 && ""}
        </div>

        {/* //////////////////////// cards section  /////////////////// */}

        <div className="row ">
          {isLoading && (
            <>
              <div className="div row">
                {Array.from({ length: 6 }).map(() => (
                  <div className="col-lg-4 mt-2">
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      animation="wave"
                      height={270}
                      className="rounded"
                    />
                  </div>
                ))}
              </div>
            </>
          )}
          {!isLoading &&
            blogs?.length > 0 &&
            blogs?.map((val, i) => {
              return (
                <>
                  <div className="col-lg-4 mt-3 h-100">
                    <Card
                      className="img-blog"
                      sx={{
                        maxWidth: 500,
                        boxShadow: "none",
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      onClick={() => handlePage(val?.id)}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="220"
                          image={picBaseUrl + val?.thumbnail}
                          className="img-blog"
                          sx={{ objectFit: "fill" }}
                          alt=""
                        />
                        <CardContent className="text-white">
                          <p className="text-secondary ">
                            By |{" "}
                            <span className="fw-bold text-white">
                              {val?.author_name}{" "}
                            </span>
                            - {moment(val?.created_at).format("MMMM D, YYYY")}
                          </p>
                          <h4>
                            {val?.title.length > 30
                              ? val?.title.substring(0, 35) + "..."
                              : val?.title}
                          </h4>

                          <p>{val?.short_description}</p>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </div>
                </>
              );
            })}
          {!isLoading && blogs?.length == 0 && (
            <>
              <div className="text-center mt-5">
                <img src={DataNotFound} alt="" width={150} height={100} />
                <h4 className="text-white">Blogs Not Found</h4>
              </div>
            </>
          )}
        </div>
      </div>
      {blogs?.length > 0 && (
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          sx={{
            mt: 4,
            display: "flex",
            justifyContent: "center",
            "& .MuiPaginationItem-root": {
              color: "white", // Set color of pagination items to gold
              borderColor: "gold", // Set outline color to gold
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "gold", // Set selected item background color to gold
              color: "black", // Set selected item text color to white
            },
            "& .MuiPaginationItem-root:hover": {
              backgroundColor: "gold", // Hover background color
              color: "black",
            },
          }}
          shape="rounded"
        />
      )}
    </div>
  );
};

export default Blog;
