import { invokeApi } from "../../utils";

export const GetProfile = async () => {
  const requestObj = {
    path: `/api/user/profile/get`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const UpdateProfile = async (data) => {
  const requestObj = {
    path: `/api/user/profile/update`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
