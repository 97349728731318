import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaLongArrowAltLeft } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { UpdateProfile } from "../../DAL/Profile/profile";
import { useSnackbar } from "notistack";
import { picBaseUrl } from "../../config/config";
import { TextField } from "@mui/material";

const ProfileEdit = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state, "dsjfhkdhskjfklsd");
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState({
    username: "",
    address: "",
    phone_no: "",
    image: "",
  });
  const [previewImage, setPreviewImage] = useState("");

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setData((prev) => ({ ...prev, ["image"]: file }));
    setPreviewImage(URL.createObjectURL(file));
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("username", data?.username);
    formData.append("phone_no", data?.phone_no);
    formData.append("address", data?.address);
    formData.append("email", data?.email);
    if (previewImage) {
      formData.append("image", data?.image);
    }

    const resp = await UpdateProfile(formData);
    if (resp?.status == true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      navigate(-1);
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  useEffect(() => {
    setData(state);
  }, []);
  return (
    <>
      <div className="container mainDiv text-white">
        <div className="container text-center">
          <div className="text-start">
            <FaLongArrowAltLeft
              onClick={() => navigate(-1)}
              style={{ cursor: "pointer" }}
            />
          </div>
          <img
            src={
              previewImage
                ? previewImage
                : data?.image.startsWith("https")
                ? data?.image
                : picBaseUrl + data?.image || "dummy2.png"
            }
            alt="Profile"
            className="img-upload"
            style={{
              height: "100px",
              width: "100px",
              backgroundColor: "white",
              borderRadius: "50%",
              border: "2px solid gold",
            }}
          />
          <input
            type="file"
            id="imageUpload"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />{" "}
          <br />
          <label htmlFor="imageUpload">
            <p className="btn btn-outline-warning mt-3">Change Profile Image</p>
          </label>{" "}
          <p
            className="text-secondary text-center"
            style={{ fontSize: "12px" }}
          >
            {moment(data?.created_at).format("MMM Do YY")}
          </p>
        </div>
        <div className="container">
          <h4 className="mx-3">Personal Information</h4>
          <hr />
          <div className=" profile-design">
            <div className="row mt-3">
              <div className="col-4 ">
                <h6>Your Name</h6>
              </div>
              <div className="col-8 ">
                <TextField
                  id="standard-basic"
                  fullWidth
                  type="text"
                  name="username"
                  onChange={handleChange}
                  value={data?.username}
                  className="text-white"
                  InputProps={{
                    style: {
                      color: "white",
                    },
                    disableUnderline: false,
                  }}
                  InputLabelProps={{
                    style: {
                      color: "white",
                      fontSize: "18px",
                    },
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white",
                    },
                  }}
                  variant="standard"
                />
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-4 ">
                <h6>Phone no</h6>
              </div>
              <div className="col-8 ">
                <TextField
                  fullWidth
                  type="number"
                  name="phone_no"
                  value={data?.phone_no}
                  onChange={handleChange}
                  className="text-white"
                  InputProps={{
                    style: {
                      color: "white",
                    },
                    disableUnderline: false,
                  }}
                  InputLabelProps={{
                    style: {
                      color: "white",
                      fontSize: "18px",
                    },
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white",
                    },
                  }}
                  variant="standard"
                />
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-4">
                <h6>Address</h6>
              </div>
              <div className="col-8 ">
                <TextField
                  type="text"
                  fullWidth
                  value={data?.address}
                  onChange={handleChange}
                  name="address"
                  className="text-white"
                  InputProps={{
                    style: {
                      color: "white",
                    },
                    disableUnderline: false,
                  }}
                  InputLabelProps={{
                    style: {
                      color: "white",
                    },
                  }}
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:hover:before": {
                      borderBottomColor: "white",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "white",
                    },
                  }}
                  style={{
                    backgroundColor: "transparent",
                    fontSize: "18px",
                  }}
                  variant="standard"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="text-end mt-3">
          <button className="btn btn-warning fw-bold" onClick={handleSubmit}>
            Save Changes
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfileEdit;
