import React, { useEffect } from "react";
import Accordion from "@mui/material/Accordion";
// import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Button from '@mui/material/Button';

const Faq = () => {
  useEffect(() => {
    document.title = "FAQs || Pakistan&#039;s 1st Online Gold Jewellery Shopping Bazaar";
  }, []);
  return (
    <div>
      <h1 className="text-warning mainDiv text-center">FAQs</h1>
      <div>
        <div className="container mt-5">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel1-content"
              id="panel1-header"
              className="accord"
            >
              How do I become a seller on Gold Bazaar?
            </AccordionSummary>
            <AccordionDetails className="accord-para">
              To become a seller, you need to apply through our Seller
              Registration page. You’ll be required to provide details about
              your business, including your contact information. Once your
              application is reviewed and approved, you’ll receive access to
              your seller account. Furthermore, you can watch a detailed video
              about the seller registration process on our{" "}
              <b>Become a Seller</b> Page.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel2-content"
              id="panel2-header"
              className="accord"
            >
              What if incorrect information is submitted during signup?
            </AccordionSummary>
            <AccordionDetails className="accord-para">
              In case there are any issues in your seller signup form, you can
              register again with correct details or contact us on +92 300
              4425678. Click here to learn more about seller signup rejection
              reasons.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel3-content"
              id="panel3-header"
              className="accord"
            >
              What types of products can I list on Gold Bazaar?
            </AccordionSummary>
            <AccordionDetails className="accord-para">
              You can list a variety of gold jewelry products (rings, necklaces,
              bracelets, etc.). All products will be reviewed before getting
              published on the app to check whether the products meet our
              quality standards and comply with legal requirements.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel3-content"
              id="panel3-header"
              className="accord"
            >
              Are there any fees associated with selling on Gold Bazaar?
            </AccordionSummary>
            <AccordionDetails className="accord-para">
              Registering as a Seller on Gold Bazaar is completely free.
              However, it will deduct a 1% of commission as a platform fee from
              the total payment of each order.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel4-content"
              id="panel4-header"
              className="accord"
            >
              What should I do if I encounter a problem with an order?
            </AccordionSummary>
            <AccordionDetails className="accord-para">
              If you encounter any issues with an order, such as disputes with
              buyers, you should contact our Seller Support team immediately. We
              will assist you in resolving the issue and provide guidance on the
              next steps.{" "}
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon className="text-white" />}
              aria-controls="panel3-content"
              id="panel3-header"
              className="accord"
            >
              Can I request for promotions on my products?
            </AccordionSummary>
            <AccordionDetails className="accord-para">
              Yes, you can request for promotions on your products. Also you can
              request for the products to add them to the trending through the
              Seller Dashboard. Be sure to comply with any promotional
              guidelines provided by Gold Bazaar.{" "}
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default Faq;
