import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { GiRoundStar } from "react-icons/gi";
import { TfiShoppingCart } from "react-icons/tfi";
import { useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";

export default function RecommendedProducts({ recommended }) {
  const navigate = useNavigate();

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleDetail = (id) => {
    navigate(`/product-detail/${id}`);
  };
  return (
    <div
      style={{
        overflowX: "scroll",
        display: "flex",
        gap: "20px",
        scrollbarWidth: "none",
      }}
    >
      {recommended?.map((val) => (
        <>
          <div className=" mt-3" style={{ width: "auto" }}>
            <Card
              className=" cardTrending h-100"
              sx={{
                padding: "10px 10px 0px 10px",
                cursor: "pointer",
              }}
              onClick={() => {
                handleScrollToTop();
                handleDetail(val?.id);
              }}
            >
              {val?.discount > 0 && (
                <button className="discountBtn">{val?.discount + "%"}</button>
              )}
              <CardMedia
                sx={{
                  height: 170,
                  width: "100%",
                  borderRadius: "10px",
                }}
                image={picBaseUrl + val?.thumbnail}
                alt=""
              />
              <CardContent>
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="text-white"
                >
                  {val?.name.length > 16
                    ? val?.name.substring(0, 16) + "..."
                    : val?.name}
                </Typography>

                <Typography
                  gutterBottom
                  variant="p"
                  component="div"
                  className="text-white"
                >
                  Rs. {val?.price}
                </Typography>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div className="d-flex ">
                    <GiRoundStar style={{ fontSize: "27px" }} color="gold" />
                    <span
                      className="mx-2  text-secondary"
                      style={{ fontSize: "19px" }}
                    >
                      {val?.rating} <span>({val?.reviews_count})</span>
                    </span>
                  </div>
                  <TfiShoppingCart
                    style={{ fontSize: "31px", marginTop: "10px" }}
                    color="gold"
                  />
                </div>
              </CardContent>
            </Card>
          </div>
        </>
      ))}
    </div>
  );
}
