import React, { useEffect, useState } from "react";
import { Typography, IconButton, Box, Button, Skeleton } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { CartListing, DeleteItem, UpdateQuantity } from "../../DAL/Cart/Cart";
import { useSnackbar } from "notistack";
import { useScroll } from "../../hooks/AppContext";
import { picBaseUrl } from "../../config/config";
import ConfirmationModel from "../../components/ConfirmationModel";
import cartGold from "../../assets/images/cartGold.png";

const Cart = () => {
  const { GetCartQuantity, NoToken } = useScroll();
  const [cartItems, setCartItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState("");
  

  const handleDecrement = async (p_id, quantity) => {
    const data = { id: p_id, quantity: quantity - 1 };
    const resp = await UpdateQuantity(data);
    if (resp?.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetCartItem();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleIncrement = async (p_id, quantity) => {
    const data = { id: p_id, quantity: quantity + 1 };
    const resp = await UpdateQuantity(data);
    if (resp?.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetCartItem();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const handleDeleteItem = (p_id) => {
    setItemId(p_id);
    setOpen(true);
  };

  const handleModelDelete = async () => {
    setOpen(false);
    const resp = await DeleteItem(itemId);
    if (resp.status === true) {
      enqueueSnackbar(resp?.message, { variant: "success" });
      GetCartItem();
      GetCartQuantity();
    } else {
      enqueueSnackbar(resp?.message, { variant: "error" });
    }
  };

  const GetCartItem = async () => {
    const resp = await CartListing();
    if (resp.status === true) {
      setCartItems(resp?.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    NoToken();
    GetCartItem();
  }, []);

  useEffect(() => {
    const grandTotal = cartItems.reduce(
      (total, item) => total + item.products.price,
      0
    );

    setTotalAmount(grandTotal);
  }, [cartItems]);

  return (
    <>
      <div className="mainDiv" style={{ width: "97%", margin: "auto" }}>
        <div
          className=" "
          style={{ backgroundColor: "#181818", marginTop: "3rem" }}
        >
          {loading === true &&
            Array.from({ length: 2 }).map(() => (
              <>
                <Skeleton
                  sx={{
                    height: 80,
                    width: "90%",
                    margin: "auto",
                    borderRadius: "10px",
                    marginTop: "10px",
                  }}
                  animation="wave"
                  variant="rectangular"
                />
              </>
            ))}

          <Box
            sx={{
              color: "#fff",
              overflowX: "scroll",
              scrollbarWidth: "none",
              width: "100%",
              padding: "1rem",
            }}
          >
            {loading == false && cartItems.length === 0 && (
              <>
                <Typography variant="h5" gutterBottom className="mx-2">
                  My Cart
                </Typography>

                <div className="text-center">
                  <img src={cartGold} alt="" height={100} width={100} />
                  <h5 className="mt-3">Your Cart is Empty</h5>
                  <p className="text-secondary">Add Products to your Cart</p>
                  <Button
                    variant="outlined"
                    sx={{
                      borderColor: "#FFC107",
                      color: "#FFC107",
                      textTransform: "none",
                    }}
                    className=" outline-btn mt-3 fw-bold"
                    onClick={() => navigate("/")}
                  >
                    Explore Products
                  </Button>
                </div>
              </>
            )}
            {loading == false && cartItems.length > 0 && (
              <>
                <div className="d-flex justify-content-between gap-3 mx-3">
                  <Typography variant="h5" gutterBottom>
                    My Cart
                  </Typography>
                  <Button
                    variant="outlined"
                    sx={{
                      border: "1px solid #ffc107",
                      color: "#ffc107",
                      textTransform: "none",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#ffc107",
                        color: "black",
                        border: "1px solid #ffc107",
                      },
                    }}
                    onClick={() => {
                      navigate("/payment-process", {
                        state: { totalAmount },
                      });
                      localStorage.setItem(
                        "cartItems",
                        JSON.stringify(cartItems)
                      );
                      localStorage.setItem("totalAmount", totalAmount);
                    }}
                  >
                    To Pay
                  </Button>
                </div>

                {cartItems.map((item) => (
                  <>
                    <div style={{ width: "93%", margin: "auto" }} key={item.id}>
                      <div className="text-start"></div>
                      <Box
                        className="row"
                        sx={{
                          backgroundColor: "#333",
                          borderRadius: "12px",
                          color: "#fff",
                          marginTop: "10px",
                          padding: "10px 0px",
                          height:{lg:"130px",md:"170px",sm:"170px",xs:"170px"},
                          alignItems:"center",
                        }}
                      >
                        <div className="col-4">
                          <Box
                            component="img"
                            src={picBaseUrl + item?.products?.thumbnail}
                            alt=""
                            sx={{
                              height: 100,
                              width: 100,

                              borderRadius: "12px",
                            }}
                          />
                        </div>
                        <div className="col-8">
                          <div className="row">
                            <div className="col-lg-6 ">
                              <h5 className="text-cart text-warning">
                                {item?.products?.name.length > 20
                                  ? item?.products?.name.substring(0, 20) +
                                    "..."
                                  : item?.products?.name}
                              </h5>
                             
                              {item?.products?.discount > 0 && (
                                <Typography
                                  variant="body1"
                                  color="white"
                                  className="text-cart"
                                >
                                  Rs.{" "}
                                  {item?.products?.discount > 0
                                    ? item?.products?.with_discount_price
                                    : item?.products?.discount}
                                </Typography>
                              )}
                              <Typography
                                variant="body1"
                                sx={{
                                  textDecoration:
                                    item?.products?.discount > 0
                                      ? "line-through"
                                      : "none",
                                  color:
                                    item?.products?.discount > 0
                                      ? "grey"
                                      : "none",
                                }}
                                className="text-cart"
                              >
                                Rs. {item?.products?.price.toFixed(2)}
                              </Typography>

                              <Typography
                                variant="body1"
                                color="grey"
                                className="text-cart"
                              >
                                Size: {item?.size}
                              </Typography>
                            </div>
                            <div
                              className=" col-lg-6"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <div className="d-flex align-items-center justify-content-center mt-2">
                                <IconButton
                                  disabled={item?.quantity == 1}
                                  onClick={() =>
                                    handleDecrement(item?.id, item?.quantity)
                                  }
                                >
                                  <RemoveIcon
                                    sx={{
                                      color:
                                        item?.quantity == 1 ? "grey" : "#fff",
                                      backgroundColor: "#373D35",
                                      borderRadius: "3px",
                                      fontSize: {
                                        lg: "25px",
                                        md: "20px",
                                        sm: "18px",
                                        xs: "15px",
                                      },
                                    }}
                                  />
                                </IconButton>
                                <Typography
                                  variant="subtitle1"
                                  color="gold"
                                  style={{
                                    minWidth: "30px",
                                    textAlign: "center",
                                    marginTop: "5px",
                                  }}
                                >
                                  {item?.quantity}
                                </Typography>
                                <IconButton
                                  onClick={() =>
                                    handleIncrement(item?.id, item?.quantity)
                                  }
                                >
                                  <AddIcon
                                    sx={{
                                      color: "#fff",
                                      backgroundColor: "#373D35",
                                      borderRadius: "3px",
                                      fontSize: {
                                        lg: "25px",
                                        md: "20px",
                                        sm: "18px",
                                        xs: "15px",
                                      },
                                    }}
                                  />
                                </IconButton>
                              </div>

                              <div className="text-end">
                                <IconButton
                                  onClick={() => handleDeleteItem(item?.id)}
                                >
                                  <DeleteIcon sx={{ color: "white" }} />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Box>
                    </div>
                  </>
                ))}
                <Box
                  sx={{
                    backgroundColor: "#ffc107",
                    color: "black",
                    padding: "16px",
                    mt: 2,
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography className="fw-bold">TOTAL AMOUNT :</Typography>
                  <Typography className="fw-bold">
                    Rs. {totalAmount.toFixed(3)}
                  </Typography>
                </Box>
              </>
            )}
          </Box>
        </div>

        <ConfirmationModel
          open={open}
          setOpen={setOpen}
          title={"Are you sure you want to delete this item?"}
          heading={"Delete Cart Item"}
          handleDeleteData={handleModelDelete}
        />
      </div>
    </>
  );
};

export default Cart;
