import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Card, CardContent } from "@mui/material";
import { color } from "@mui/system";
import moment from "moment/moment";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "70%", md: "70%", sm: "60%", xs: "90%" },
  bgcolor: "#383838",
  boxShadow: 24,
  borderRadius: "10px",
  p: 2,
};

export default function ScheduleModal({ open, setOpen, data }) {
  const handleClose = () => setOpen(false);
  console.log(data, "jkdfgkjdfkljgklfd");
  const getFormattedDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0"); // Ensures two digits for day
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[today.getMonth()]; // Gets the abbreviated month
    const year = today.getFullYear(); // Gets the full year

    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-between">
            <Typography variant="subtitle1">
              <span style={{ color: "#848484" }}>Today</span>{" "}
              <span style={{ color: "white", marginLeft: "10px" }}>
                {getFormattedDate()}
              </span>
            </Typography>
            <p
              className="model-cross-icon"
              style={{
                backgroundColor: "#6C6C6C",
                padding: "0px 5px 3px 5px",
                color: "white",
                borderRadius: "50px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            >
              <CloseIcon sx={{ fontSize: "18px" }} />
            </p>
          </div>
          <Typography
            id="modal-modal-title"
            variant="h4"
            component="h2"
            className="text-center text-white"
          >
            Schedule
          </Typography>
          <div
            className="model-content bg-dark p-4 rounded mt-2"
            style={{
              overflowY: "scroll",
              height: "80vh",
              scrollbarWidth: "none",
            }}
          >
            {data?.length == 0 && (
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ height: "80vh" }}
              >
                <h4 className="text-white">No Schedule Available</h4>
              </div>
            )}
            <div className="row">
              {data?.map((val) => {
                return (
                  <>
                    <div className="col-lg-4 mt-4">
                      <Card
                        sx={{
                          backgroundColor: "#2C2C2C",
                          borderRadius: "10px",
                          borderLeft: `10px solid ${
                            val?.days === "Monday"
                              ? "#CE998E"
                              : val?.days === "Tuesday"
                              ? "#D8DA8A"
                              : val?.days == "Wednesday"
                              ? "#8ADA92"
                              : val?.days == "Thursday"
                              ? "#8ACBDA"
                              : val?.days == "Friday"
                              ? "#CA8ADA"
                              : val?.days == "Saturday"
                              ? "#DA8A8A"
                              : "#A1BAA4"
                          }`,
                          height: "150px",
                        }}
                      >
                        {console.log(val, "djksfhkdkljfkldj")}
                        <CardContent>
                          <Typography
                            gutterBottom
                            sx={{ color: `white`, fontSize: 14 }}
                          >
                            {val?.days}
                          </Typography>
                          {val?.start_time !== "" && val?.end_time !== "" && (
                            <div className="text-white mt-3 mb-2">
                              <span class="start-time">
                                {moment(val.start_time).format("h:mm A")}
                              </span>
                              {val?.day !== "Sunday" && (
                                <>
                                  <span class="divider">------------</span>
                                  <span>-</span>
                                </>
                              )}
                              <span class="end-time">
                                {moment(val.end).format("h:mm A")}
                              </span>
                            </div>
                          )}

                          {val?.start_time !== "" && val?.end_time !== "" && (
                            <div
                              class="d-flex justify-content-between"
                              style={{ width: "85%" }}
                            >
                              <span
                                class="start-label"
                                style={{ color: "gold", fontSize: 18 }}
                              >
                                Start
                              </span>
                              <span
                                class="end-label"
                                style={{ color: "gold", fontSize: 18 }}
                              >
                                End
                              </span>
                            </div>
                          )}
                          {(val?.holiday == 1 ||
                            val?.start_time == "" ||
                            val?.end_time == "") && (
                            <div>
                              <Typography
                                variant="h5"
                                className="swing p-3 my-2 fw-bold text-center text-white"
                              >
                                CLOSED
                              </Typography>
                            </div>
                          )}
                        </CardContent>
                      </Card>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
