import { invokeApi } from "../../utils";

export const GetSellerFollowedShops = async () => {
  const requestObj = {
    path: `/api/user/seller/follow`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const GetSellerShopInfo = async (id) => {
  const requestObj = {
    path: `/api/user/seller/detail/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};

export const ShopFollowUnFollow = async (data) => {
  const requestObj = {
    path: `/api/user/seller/toggelfollow`,
    method: "POST",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const SellerScheduleInfo = async (id) => {
  const requestObj = {
    path: `/api/user/seller/schedule/${id}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const GetSellerAllItems = async (id, page) => {
  const requestObj = {
    path: `/api/user/seller/product/${id}?page=${page}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("web_token")}`,
    },
  };
  return invokeApi(requestObj);
};
