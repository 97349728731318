import React from "react";
import feature1 from "../../assets/images/feature-1.svg"
import feature2 from "../../assets/images/feature-2.svg"
import feature3 from "../../assets/images/feature-3.svg"
import feature4 from "../../assets/images/feature-4.svg"
import feature5 from "../../assets/images/feature-5.svg"
import feature6 from "../../assets/images/feature-6.svg"

export default function FeatureSection() {
  return (
    <>
      <section className="container text-white">
        <h1 className="text-white text-center mt-5">Features</h1>
        <div className="contaainer">
          <div className="row mt-5">
            <div
              className="col-lg-4 text-center "
              style={{
                border: "1px solid black",
                padding: "10px 18px 10px 18px",
              }}
            >
              <div
                className=" mb-3 text-white "
                style={{ width: "100%", backgroundColor: "transparent" }}
              >
              <h2
                  style={{
                    backgroundColor: "black",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    margin: "auto",
                  }}
                >
                  <img src={feature5} alt="" />
                </h2>
               

                <div className="card-body text-center mt-3">
                  <h4 className="card-title text-warning">
                    Exclusive Promotions
                  </h4>
                  <p className=" mt-3">
                    Our app is dedicated to bringing you the best deals and
                    promotions on gold jewelry. With new offers added regularly,
                    there’s always something exciting to discover.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 text-center "
              style={{
                border: "1px solid black",
                padding: "10px 18px 10px 18px",
              }}
            >
              <div
                className=" mb-3 text-white "
                style={{ width: "100%", backgroundColor: "transparent" }}
              >
              <h2
                  style={{
                    backgroundColor: "black",
                    borderRadius: "50%",
                    padding: "10px",
                    width: "60px",
                    height: "60px",
                    margin: "auto",
                  }}
                >
                  <img src={feature1} alt="" />
                </h2>
                

                <div className="card-body text-center mt-3">
                  <h4 className="card-title text-warning">Trusted Sellers</h4>
                  <p className=" mt-3">
                    We partner with reputable sellers who are committed to
                    providing exceptional products and services. Each seller
                    undergoes a thorough vetting process to ensure they meet our
                    high standards of quality and reliability.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 text-center "
              style={{
                border: "1px solid black",
                padding: "10px 18px 10px 18px",
              }}
            >
              <div
                className=" mb-3 text-white "
                style={{ width: "100%", backgroundColor: "transparent" }}
              >
                <h2
                  style={{
                    backgroundColor: "black",
                    borderRadius: "50%",
                    padding: "8px",
                    width: "60px",
                    height: "60px",
                    margin: "auto",
                  }}
                >
                  <img src={feature6} alt="" />
                </h2>
                

                <div className="card-body text-center mt-3">
                  <h4 className="card-title text-warning">
                    Secure Transaction
                  </h4>
                  <p className=" mt-3">
                    Your security is our top priority. Gold Bazaar uses advanced
                    encryption and secure payment gateways to protect your
                    personal and financial information. Shop with confidence
                    knowing your data is safe with us.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 text-center "
              style={{
                border: "1px solid black",
                padding: "10px 18px 10px 18px",
              }}
            >
              <div
                className=" mb-3 text-white "
                style={{ width: "100%", backgroundColor: "transparent" }}
              >
                <h2
                  style={{
                    backgroundColor: "black",
                    borderRadius: "50%",
                    padding: "10px",
                    width: "60px",
                    height: "60px",
                    margin: "auto",
                  }}
                >
                  <img src={feature4} alt="" />
                </h2>

                <div className="card-body text-center mt-3">
                  <h4 className="card-title text-warning">Customer Support</h4>
                  <p className=" mt-3">
                    Our dedicated customer support team is always here to assist
                    you. Whether you have questions about a product, need help
                    with your order, or have any other inquiries, we are just a
                    click away.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 text-center "
              style={{
                border: "1px solid black",
                padding: "10px 18px 10px 18px",
              }}
            >
              <div
                className=" mb-3 text-white "
                style={{ width: "100%", backgroundColor: "transparent" }}
              >
                 <h2
                  style={{
                    backgroundColor: "black",
                    borderRadius: "50%",
                    padding: "5px",
                    width: "60px",
                    height: "60px",
                    margin: "auto",
                  }}
                >
                  <img src={feature3} alt="" />
                </h2>
                <div className="card-body text-center mt-3">
                  <h4 className="card-title text-warning">
                    Diverse Payment Options
                  </h4>
                  <p className=" mt-3">
                    We offer a variety of payment methods to suit your
                    convenience, including credit/debit cards, mobile wallets,
                    and more. Choose the payment option that works best for you.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 text-center "
              style={{
                border: "1px solid black",
                padding: "10px 18px 10px 18px",
              }}
            >
              <div
                className=" mb-3 text-white "
                style={{ width: "100%", backgroundColor: "transparent" }}
              >
              <h2
                  style={{
                    backgroundColor: "black",
                    borderRadius: "50%",
                    width: "60px",
                    height: "60px",
                    margin: "auto",
                  }}
                >
                  <img src={feature2} alt="" />
                </h2>
              

                <div className="card-body text-center mt-3">
                  <h4 className="card-title text-warning">Easy Return</h4>
                  <p className=" mt-3">
                    Your security is our top priority. Gold Bazaar uses advanced
                    encryption and secure payment gateways to protect your
                    personal and financial information. Shop with confidence
                    knowing your data is safe with us.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
