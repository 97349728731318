import React from 'react'
import coming from '../../assets/images/coming.jpg'

const Coming = () => {
  return (
    <div style={{height:"100vh"}}>
      <img src={coming} alt="" style={{height:"100vh",width:"100%"}}/>
    </div>
  )
}

export default Coming
