import { invokeApi } from "../../utils";

export const SearchApi = async (query, page) => {
  const requestObj = {
    path: `/api/web/search/product?search=${query}&page=${page}`,
    method: "GET",
    headers: {},
  };
  return invokeApi(requestObj);
};
