import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { picBaseUrl } from "../../config/config";

export default function ImageSlider({ data }) {
  // Slider settings
  var settings = {
    dots: true, // Disable default dots
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {data?.length > 1 ? (
        <Slider {...settings}>
          {data.map((val, index) => (
            <div key={index}>
              <img
                src={picBaseUrl + val?.image}
                alt={`slide-${index}`}
                style={{
                  width: "100%",
                  height: "440px",
                  objectFit: "contained",
                }}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div>
          {data !== undefined && (
            <img
              src={picBaseUrl + data[0]?.image}
              alt="single-slide"
              style={{ width: "100%", height: "440px", objectFit: "contained" }}
            />
          )}
        </div>
      )}
    </>
  );
}
