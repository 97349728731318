import React, { useEffect } from "react";

const Termsofuse = () => {
  useEffect(() => {
    document.title = "Terms of Use || Pakistan&#039;s 1st Online Gold Jewellery Shopping Bazaar";
  }, []);
  return (
    <div className="container policy mainDiv text-white">
      <div className="text-center">
        <p className="text-center">Last updated: August 23, 2024</p>
        <h1 className="text-warning">Terms of Use</h1>
      </div>
      <div className="terms">
        <p>
          Welcome to Gold Bazaar also hereby known as “we", “us” or "our". By
          accessing or using our mobile app, website and services, you agree to
          comply with and be bound by these Terms of Use. Please read them
          carefully before using our services.
        </p>
        <h2>Acceptance of Terms</h2>
        <p>
          By accessing or using the Gold Bazaar website, mobile application, or
          any other services provided by us, you agree to be bound by these
          Terms of Use, our Privacy Policy, and any other policies or guidelines
          we may publish. If you do not agree with any part of these terms, you
          must not use our Services.
        </p>
        <h2>Eligibility</h2>
        <p>
          You must be at least 18 years old to use our Services. By using our
          Services, you represent and warrant that you meet this age
          requirement.
        </p>
        <h2>Account Registration</h2>
        <ul>
          <li>
            <b>Account Creation</b>
          </li>
          <p>
            To access certain features of our Services, you may be required to
            create an account. You agree to provide accurate, current, and
            complete information during the registration process and to update
            such information as necessary.
          </p>
          <li>
            <b>Account Security</b>
          </li>
          <p>
            You are responsible for maintaining the confidentiality of your
            account login credentials and for all activities that occur under
            your account. You agree to notify us immediately of any unauthorized
            use of your account.
          </p>
        </ul>
        <h2>Use of Services</h2>
        <p>
          You may use our Services only for lawful purposes and in accordance
          with these Terms of Use. You cannot use our services to violate any
          applicable federal, state, local, or international law or regulation
          (e.g. using the platform to sell counterfeit goods or products that
          violate intellectual property laws).
        </p>
        <h2>Product Listing and Orders</h2>
        <ul>
          <li>
            <b>Product Descriptions</b>
          </li>
          <p>
            We strive to ensure that the product descriptions, prices, and
            availability information provided on our website are accurate.
            However, we do not warrant that such information is error-free,
            complete, or current.
          </p>
          <li>
            <b>Order Acceptance</b>
          </li>
          <p>
            All orders are subject to our acceptance. We reserve the right to
            refuse or cancel any order for any reason, including but not limited
            to product availability, errors in product or pricing information.
          </p>
        </ul>
        <h2>Pricing & Payments</h2>
        <p>
          The final price for your order will be displayed at checkout,
          including any discounts. By placing your order you agree to pay all
          the charges associated with your order. We accept payment through
          credit card and cash on delivery. You can pay 10% advance and the
          remaining amount on delivery or full payment advance. Your payment
          information will be processed securely.
        </p>
        <h2>User Content</h2>
        <ul>
          <li>
            <b>User Submissions</b>
          </li>
          <p>
            By submitting or posting content on our website, including reviews,
            feedback, or other materials, you grant us a non-exclusive,
            worldwide, royalty-free license to use, reproduce, modify, and
            distribute such content in connection with the operation of our
            Services.
          </p>
          <li>
            <b>Prohibited Content</b>
          </li>
          <p>
            You agree not to post or transmit any content that is unlawful,
            defamatory, obscene, or otherwise objectionable, or that infringes
            on the rights of others.
          </p>
          <li>
            <b>Duplicate Content</b>
          </li>
          <p>
            We list thousands of products for sale offered by numerous sellers
            on the Site. So it is not possible for us to be aware of the
            contents of each product listed for sale that it is duplicate or
            unique. However, if you find anything like this you can complain by
            contacting us on +923004425678.
          </p>
        </ul>
        <h2>Communication with us</h2>
        <p>
          By visiting the Site, sending us emails or contacting us on the phone,
          you are communicating with us electronically. When placing an order,
          you must provide a valid Name, phone number, email address and billing
          address. We may communicate with you via email, phone calls or mobile
          app notification.{" "}
        </p>
        <h2>Site Availability </h2>
        <p>
          We strive to ensure the Site is accessible, uninterrupted, and
          error-free. However, due to the nature of the Internet and our
          platform, this cannot be guaranteed. Occasionally, your access may be
          suspended or restricted for repairs, maintenance, or the introduction
          of new features, without prior notice. We will do our best to minimize
          the frequency and duration of these interruptions.
        </p>
        <h2>Disclaimer</h2>
        <p>
          We are not responsible for any transactions made outside the Gold
          Bazaar Platform. All exchange of messages and information should be
          done within the official communication channels available on the Gold
          Bazaar Platform.
        </p>
        <h2>Reviews and Feedback</h2>
        <ul>
          <li>
            Please provide honest reviews and feedback about our products and
            application.
          </li>
          <li>
            Do not post false or misleading reviews, or any content that is
            inappropriate, irrelevant, discriminatory, threatening, or illegal.
            Otherwise, these will be removed.
          </li>
        </ul>
        <h2>Intellectual Property</h2>
        <ul>
          <li className="fw-bold">Trademarks and Copyrights</li>
          <p>
            All trademarks, logos, and service marks displayed on the Gold
            Bazaar mobile app and website are the property of the Gold Bazaar.
            You are not granted any rights or licenses to use these trademarks
            without the express written permission of their owners.
          </p>
          <li>
            <b className="fw-bold">Ownership</b>
          </li>
          <p>
            All content, materials, and features available on our website,
            including but not limited to text, graphics, logos, images, and
            software, are the property of Gold Bazaar and are protected by
            intellectual property laws.
          </p>
          <li>
            <b className="fw-bold">License</b>
          </li>
          <p>
            We grant you a limited, non-exclusive, non-transferable, and
            revocable license to access and use our Services for personal,
            non-commercial purposes. Any unauthorized use of our content may
            violate intellectual property rights and is strictly prohibited.
          </p>
        </ul>
        <h2>Termination</h2>
        <p>
          We may terminate or suspend your access to our Services at our sole
          discretion, without notice, for conduct that we believe violates these
          Terms of Use or is harmful to other users or our business interests.
        </p>
        <h2>Governing Laws</h2>
        <p>
          These Terms of Use and any disputes arising out of or related to them
          shall be governed by and construed in accordance with the laws of
          Pakistan, without regard to its conflict of law principles.
        </p>
        <h2>Changes to our Terms of Use</h2>
        <p>
          We reserve the right to modify or update these Terms of Use at any
          time. Your continued use of our Services after any such changes
          constitutes your acceptance of the new Terms of Use.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about these Terms of Use, you can contact us
          at:
        </p>
        <ul>
          <li>
            <b>Phone:</b> +923004425678
          </li>
          <li>
            <b>Email:</b> support@goldbazaar.com
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Termsofuse;
