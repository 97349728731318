import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { GiRoundStar } from "react-icons/gi";
import { TfiShoppingCart } from "react-icons/tfi";
import { Box, Grid, Pagination, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { picBaseUrl } from "../../config/config";
import { GetSellerAllItems } from "../../DAL/SellerShop/SellerShop";

export default function AllItems({ shopId }) {
  const navigate = useNavigate();
  const [allItems, setAllItems] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const [loading, setLoading] = React.useState(false);
  const handleChange = (e, newValue) => {
    setPage(newValue);
  };
  const GetAllItemsData = async () => {
    setLoading(true);
    const resp = await GetSellerAllItems(shopId, page);
    if (resp.status == true) {
      setAllItems(resp?.data?.data);
      setTotalPages(Math.ceil(resp?.data?.total / resp?.data?.per_page));
      setLoading(false);
    }
  };

  React.useEffect(() => {
    GetAllItemsData();
  }, [page]);
  return (
    <>
        {loading == true &&
          Array.from({ length: 20 }).map(() => (
            <>
              <Grid item lg={3} md={4} sm={6} xs={6} sx={{ marginTop: "30px" }}>
                <Skeleton
                  sx={{
                    height: 330,
                    width: "100%",
                    borderRadius: "20px",
                  }}
                  animation="wave"
                  variant="rectangular"
                />
              </Grid>
            </>
          ))}
      <Grid container spacing={2}>
        {loading == false &&
          allItems?.length > 0 &&
          allItems?.map((val) => (
            <>
              <Grid item lg={3} xs={6} sx={{ marginTop: "30px" }}>
                <Card
                  className="card cardTrending h-100"
                  sx={{
                    padding: {
                      lg: "10px 10px 0px 10px",
                      md: "10px 10px 0px 10px",
                      sm: "5px 5px 0px 5px",
                      xs: "6px 6px 0px 6px",
                    },
                    width: { xs: `100% !important`, sm: `100% !important` },
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/product-detail/${val?.id}`)}
                >
                  {val?.discount > 0 && (
                    <button className="discountBtn">
                      {val?.discount + "%"}
                    </button>
                  )}
                  <CardMedia
                    sx={{
                      height: { lg: 170, md: 170, sm: 130, xs: 120 },
                      width: "100%",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    image={picBaseUrl + val?.thumbnail}
                    alt=""
                  />
                  <CardContent
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      className="text-white"
                      sx={{
                        fontSize: {
                          lg: "1.3rem",
                          md: "1rem",
                          sm: "0.76rem",
                          xs: "0.76rem",
                        },
                      }}
                    >
                      {val?.name.length > 17
                        ? val?.name.substring(0, 17) + "..."
                        : val?.name}
                    </Typography>

                    <Typography
                      gutterBottom
                      variant="p"
                      component="div"
                      className="text-white"
                      sx={{
                        fontSize: {
                          lg: "1.3rem",
                          md: "1rem",
                          sm: "0.76rem",
                          xs: "0.76rem",
                        },
                      }}
                    >
                      Rs. {val?.price}
                    </Typography>
                    <div className="d-flex justify-content-between align-items-center mt-3 ">
                      <div className="d-flex ">
                        <GiRoundStar
                          sx={{
                            fontSize: {
                              lg: "1.3rem",
                              md: "1rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                          color="gold"
                        />
                        <span
                          className="mx-2  text-secondary"
                          sx={{
                            fontSize: {
                              lg: "1.3rem",
                              md: "1rem",
                              sm: "0.76rem",
                              xs: "0.76rem",
                            },
                          }}
                        >
                          {val?.rating} <span>({val?.reviews_count})</span>
                        </span>
                      </div>
                      <TfiShoppingCart
                        style={{ fontSize: "31px", marginTop: "10px" }}
                        color="gold"
                      />
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ))}
      </Grid>
      <div className="d-flex justify-content-center mt-5">
        <Pagination
          count={totalPages}
          shape="rounded"
          page={page}
          siblingCount={0}
          onChange={handleChange}
          rowsPerPage={5}
          sx={{
            "& .MuiPaginationItem-root": {
              color: "white",
              borderColor: "gold",
            },
            "& .MuiPaginationItem-root.Mui-selected": {
              backgroundColor: "gold",
              color: "black",
            },
            "& .MuiPaginationItem-root:hover": {
              backgroundColor: "gold",
              color: "black",
            },
          }}
        />
      </div>
    </>
  );
}
