import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "welcome": "Pakistan No. 1 Gold Treasury",
      "buttonText": "Click to change language",
      "content": "On ensure switch bells job stakeholders. Hurting container box brainstorming items air. <br /> Performance leverage production re-inventing me dangerous going fit. Diarize while launch close strategy <br /> info highlights. Respectively catching initiative pollination first-order cob win-win-win."
    },
  },
  ur: {
    translation: {
      "welcome" :"پاکستان نمبر 1 گولڈ ٹریژری",
      "buttonText": "زبان تبدیل کرنے کے لئے کلک کریں",
      "content": "اس بات کو یقینی بنانے پر کہ نوکری کے اسٹیک ہولڈرز کو گھنٹی بجائیں۔ تکلیف دہ کنٹینر باکس ذہن سازی اشیاء ہوا. <br /> پرفارمنس لیوریج پروڈکشن مجھے دوبارہ ایجاد کرنا خطرناک ہے۔ فٹ ہو رہا ہے قریبی حکمت عملی شروع کرتے وقت ڈائرائز کریں۔ <br /> معلومات کی جھلکیاں۔ بالترتیب پکڑنے کی پہل پولینیشن فرسٹ آرڈر cob جیت جیت۔"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // Default language
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
