import React, { useEffect } from "react";

const Privacy = () => {
  useEffect(() => {
    document.title = "Privacy Policy || Pakistan&#039;s 1st Online Gold Jewellery Shopping Bazaar";
  }, []);
  return (
    <div className="container policy mainDiv  text-white">
      <div className="text-center ">
        <p className="text-center ">Last updated: August 23, 2024</p>
        <h1 className="text-warning">Privacy Policy</h1>
      </div>
      <div className="privacy">
        <p>
          Welcome to the Gold Bazaar website. We are committed to protecting
          your privacy and ensuring that your personal information is handled in
          a safe and responsible manner. This Privacy Policy explains how we
          collect, use, and protect your information when You use the Service
          and tells You about Your privacy rights and how the law protects You.
          By using the Service, You agree to the collection and use of
          information in accordance with this Privacy Policy.{" "}
        </p>
        <h2>Information We Collect</h2>
        <p>
          We use this information to provide features of Our Service, to improve
          and customize Our Service. The information may be uploaded to the
          Company's servers and/or a Service Provider's server or it may be
          simply stored on Your device. You can enable or disable access to this
          information at any time, through your Device settings. Following is
          the information we collect:
        </p>
        <ul>
          <li>Your location</li>
          <p>
            While using Our Application, in order to provide features of Our
            Application, We may collect information regarding your location,
            with Your prior permission.
          </p>
          <li>Personal data</li>
          <p>
            When you create a user account, make a purchase, or contact us, we
            may collect personal information such as your name, email address,
            phone number, and shipping address.
          </p>
          <p>
            When you create a seller account, or contact us, we may collect
            personal and shop information such as your name, email, phone no,
            CNIC no, address, location, zip code, CNIC front and back side
            image, Shop name, Shop image, Shop Phone number, Shop Address.
          </p>
          <li>Payment information</li>
          <p>
            We collect seller’s payment details to process transactions
            securely. We collect your payment details such as bank account name,
            account title and account number.
          </p>
          <li>Usage data</li>
          <p>Usage Data is collected automatically when using the Service.</p>
          <p>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>
          <p>
            When You access the Service using a mobile device, We may collect
            certain information automatically, including, but not limited to,
            the type of mobile device You use, Your mobile device unique ID, the
            IP address of Your mobile device, Your mobile operating system, the
            type of mobile Internet browser You use, unique device identifiers
            and other diagnostic data.
          </p>
          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <li>Cookies and tracking</li>
          <p>
            We use cookies to enhance your experience on our website and to
            analyse usage patterns.
          </p>
        </ul>
        <h2>How We Use Your Information</h2>
        <p>We may use your information for the following purposes:</p>
        <ul>
          <li>To manage your account</li>
          <p>
            We can use your personal information to manage your registration as
            a user of the service. The personal data you provide can give you
            access to different functionalities of the service that are
            available to you as a registered user.
          </p>
          <li>To contact you</li>
          <p>
            To contact You by email, phone call, SMS, or other equivalent forms
            of electronic communication, such as a mobile application's push
            notifications regarding updates or informative communications
            related to the functionalities, products, selling and purchasing of
            products, promotions and security updates, when necessary.
          </p>
          <li>To process transactions</li>
          <p>
            We use your payment and shipping information to process and fulfill
            your orders.
          </p>
          <li>To improve our services</li>
          <p>
            We use your data to improve our website's performance, data
            analysis, identifying usage trends, determining the effectiveness of
            our promotional campaigns and to evaluate and improve our Service,
            products, services, marketing and your experience.
          </p>
          <li>To comply with legal obligations</li>
          <p>
            We may use your information to comply with applicable laws,
            regulations, and legal processes.
          </p>
        </ul>
        <h2>Sharing Your Information</h2>
        <p>
          We do not sell, trade, or rent your personal information to third
          parties. We may share Your personal information in the following
          situations:
        </p>
        <ul>
          <li>With service providers</li>
          <p>
            We may share Your personal information with service providers to
            monitor and analyze the use of our Service, to contact you.
          </p>
          <li>With your consent</li>
          <p>
            We may disclose your personal information for any other purpose with
            your consent.
          </p>
          <li>With legal Authorities</li>
          <p>
            We may disclose your information if required by law or to protect
            our rights and safety.
          </p>
        </ul>
        <h2>Retention of Your Personal Data</h2>
        <p>
          The Gold Bazaar will retain your personal data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use your personal data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p>
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <h2>Security of Your Personal Data</h2>
        <p>
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>
        <h2>Your Rights</h2>
        <p>You have the right to:</p>
        <ul>
          <li>
            Access Your Information:{" "}
            <span style={{ fontSize: "16px" }}>
              Request access to the personal information we hold about you.
            </span>
          </li>
          <li>
            Update Your Information:{" "}
            <span style={{ fontSize: "16px" }}>
              Correct or update any inaccurate or incomplete information.
            </span>
          </li>
          <li>
            Delete Your Information:{" "}
            <span style={{ fontSize: "16px" }}>
              Request the deletion of your personal information, subject to
              certain legal obligations.
            </span>
          </li>
        </ul>
        <p>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
        <h2>Children’s Privacy</h2>
        <p>
          We do not sell products to individuals under 18 and do not knowingly
          collect their personal data. By using this Site, you confirm that you
          are over 18 and capable of understanding and accepting this Privacy
          Policy. If you allow a child to use your account to make purchases,
          you consent to the processing of their personal data and accept
          responsibility for their actions under this policy. We are not liable
          for any unauthorized use of your account; it is your responsibility to
          make informed decisions and take steps to prevent misuse of the Site.
        </p>
        <h2>Third-Party Links</h2>
        <p>
          Our Service may contain links to other websites that are not operated
          by Us. If you click on a third party link, You will be directed to
          that third party's site. We strongly advise you to review the Privacy
          Policy of every site You visit.
        </p>
        <p>
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>
        <h2>Changes to this Privacy Policy</h2>
        <p>
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p>
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions about this Privacy Policy, You can contact
          us:
        </p>
        <ul>
          <li>
            Phone: <span>+923004425678</span>
          </li>
          <li>
            Email: <span> support@goldbazaar.com</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Privacy;
